import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';

const module = angular.module('panel', []);

interface PanelController extends angular.IStrictController {
    title: string;
}

module.component('panel', {
    template: template,
    transclude: {
        'panel-title': 'panelTitle',
        'panel-notice': 'panelNotice',
        'panel-content': 'panelContent'
    },
    bindings: { title: '<' },
    controller: function(this: PanelController) {}
});

export { module };

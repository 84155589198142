import * as angular from 'angular';
import * as template from './template.html';
import * as _ from 'lodash';
import { HippoApiService } from 'services/hippoApi';
import './styling.scss';

const module = angular.module('lender-autocomplete', []);

interface MortgageeClauseFormScope extends angular.IScope {
    mortgageeClauseForm: angular.IFormController;
}

interface HippoLenderDropdownController extends angular.IStrictController {
    mortgageDetails: MortgageDetails;
    selectLender: (lender: KnownLenderInfo) => void;
    showLenderOptions: boolean;
    lenderOptions: Array<KnownLenderInfo>;
    filteredOptions: {
        [key: string]: KnownLenderInfo;
    };
    lenderName: string;
    toggleLenderDropdown: (show: boolean) => void;
    selectLenderOption: (lender: KnownLenderInfo) => void;
    filter: () => void;
    keyboardSelect: number;
    maxLenderNameInputLength: number;
    clearLenderInputValue: () => void;
    element: angular.IAugmentedJQuery;
}

module.component('lenderAutocomplete', {
    bindings: {
        lenderName: '=',
        selectLender: '='
    },
    template: template,
    controller: function(
        this: HippoLenderDropdownController,
        $element: angular.IAugmentedJQuery,
        $timeout: angular.ITimeoutService,
        hippoApiService: HippoApiService
    ) {
        this.$onInit = () => {
            this.lenderOptions = [];
            this.filteredOptions = {};
            this.keyboardSelect = 0;
            this.element = $element; // losing access to $element after $onInit
            // TODO: Limit fetch results instead of grabbing all lenders at once
            hippoApiService.getKnownLenders().then((lenders) => {
                this.lenderOptions = lenders;
                this.filteredOptions = _.keyBy(lenders, 'description');
            });

            $element
                .find('input')
                .on('focus', (event) => {
                    this.filter();
                })
                .on('blur', (event) => {
                    this.toggleLenderDropdown(false);
                })
                .on('keydown', (event) => {
                    const options = Object.keys(this.filteredOptions);
                    if (options.length > 0) {
                        if (event.keyCode === 38) {
                            // Arrow up
                            $timeout(() => {
                                this.keyboardSelect = (this.keyboardSelect || options.length) - 1;
                            });
                            event.preventDefault();
                        } else if (event.keyCode === 40) {
                            // Arrow down
                            $timeout(() => {
                                const newVal = (this.keyboardSelect + 1) % options.length;
                                this.keyboardSelect = newVal;
                            });
                            event.preventDefault();
                        } else if (event.keyCode === 13) {
                            // Enter
                            // Can only select using Enter is options are displayed
                            if (this.showLenderOptions) {
                                const lender = this.filteredOptions[options[this.keyboardSelect]];
                                this.selectLender(lender);
                                event.preventDefault();
                            }
                        }
                    }
                });
        };

        this.filter = () => {
            if (this.lenderName && this.lenderName.length >= 3) {
                this.keyboardSelect = 0;
                this.toggleLenderDropdown(true);
                const filtered = this.lenderOptions.filter((lender) => {
                    const name = lender.value.toLowerCase();
                    const fullAddress = lender.description.toLowerCase();
                    const currentInput = this.lenderName.toLowerCase();
                    const matchName = !!name.match(currentInput);
                    const matchFullAddress = !!fullAddress.match(currentInput);
                    return matchName || matchFullAddress;
                });
                this.filteredOptions = _.keyBy(filtered, 'description');
            } else {
                this.toggleLenderDropdown(false);
            }
        };

        this.selectLenderOption = (lender: KnownLenderInfo) => {
            this.selectLender(lender);
            this.toggleLenderDropdown(false);
        };

        this.toggleLenderDropdown = (show: boolean) => {
            $timeout(() => (this.showLenderOptions = show));
        };

        const focusLenderInput = () => {
            const lenderInput = document.getElementById('mortgageeClause') as HTMLElement;

            if (lenderInput) {
                lenderInput.focus();
            }
        };

        const getMortgageeClauseForm = (element: angular.IAugmentedJQuery) => {
            const form = element.scope() as MortgageeClauseFormScope;
            return form.mortgageeClauseForm;
        };

        this.clearLenderInputValue = () => {
            const mortgageeClauseForm = getMortgageeClauseForm(this.element);
            this.lenderName = '';
            mortgageeClauseForm.name.$setViewValue();
            mortgageeClauseForm.name.$render();
            focusLenderInput();
        };
    }
});

export { module };

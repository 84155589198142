import { ILocationProvider } from 'angular';
import { StateProvider, UrlService } from '@uirouter/angularjs';
import { states } from 'states';

export default function UIRouterConfig(
    $stateProvider: StateProvider,
    $urlServiceProvider: UrlService,
    $locationProvider: ILocationProvider
) {
    'ngInject';

    $stateProvider.state({
        name: 'contact-us',
        url: '/',
        template: states.contactUs.template,
        controller: states.contactUs.controller,
        onEnter: states.contactUs.onEnter,
        onExit: states.contactUs.onExit
    });


    $urlServiceProvider.rules.otherwise('');
    $locationProvider.html5Mode(true);
}

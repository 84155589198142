import * as angular from 'angular';

import './styling.scss';
import * as template from './template.html';

const module = angular.module('app-header-component', []);

interface AppHeaderController extends angular.IStrictController {}

module.component('appHeader', {
    template: template,
    bindings: {},
    transclude: true,
    controller: function(this: AppHeaderController) {}
});

export { module };

import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';

// TODO: Reuse this componenet for escrow form (Customer portal and Lender portal*payment*)
const module = angular.module('state-dropdown', []);

interface HippoStateDropdownController extends angular.IStrictController {
    state: string;
    showStateOptions: boolean;
    stateOptions: typeof STATES_ABBREVIATION_TO_STATE_NAME_MAP;
    toggleStateDropdown: (show: boolean) => void;
    selectStateOption: (state: string) => void;
}

const STATES_ABBREVIATION_TO_STATE_NAME_MAP = {
    al: 'Alabama',
    ak: 'Alaska',
    az: 'Arizona',
    ar: 'Arkansas',
    ca: 'California',
    co: 'Colorado',
    ct: 'Connecticut',
    de: 'Delaware',
    dc: 'District Of Columbia',
    fl: 'Florida',
    ga: 'Georgia',
    hi: 'Hawaii',
    id: 'Idaho',
    il: 'Illinois',
    in: 'Indiana',
    ia: 'Iowa',
    ks: 'Kansas',
    ky: 'Kentucky',
    la: 'Louisiana',
    me: 'Maine',
    md: 'Maryland',
    ma: 'Massachusetts',
    mi: 'Michigan',
    mn: 'Minnesota',
    ms: 'Mississippi',
    mo: 'Missouri',
    mt: 'Montana',
    ne: 'Nebraska',
    nv: 'Nevada',
    nh: 'New Hampshire',
    nj: 'New Jersey',
    nm: 'New Mexico',
    ny: 'New York',
    nc: 'North Carolina',
    nd: 'North Dakota',
    oh: 'Ohio',
    ok: 'Oklahoma',
    or: 'Oregon',
    pa: 'Pennsylvania',
    ri: 'Rhode Island',
    sc: 'South Carolina',
    sd: 'South Dakota',
    tn: 'Tennessee',
    tx: 'Texas',
    ut: 'Utah',
    vt: 'Vermont',
    va: 'Virginia',
    wa: 'Washington',
    wv: 'West Virginia',
    wi: 'Wisconsin',
    wy: 'Wyoming'
};

module.component('stateDropdown', {
    bindings: {
        state: '='
    },
    template: template,
    controller: function(
        this: HippoStateDropdownController,
        $element: angular.IAugmentedJQuery,
        $timeout: angular.ITimeoutService
    ) {
        this.$onInit = () => {
            this.stateOptions = STATES_ABBREVIATION_TO_STATE_NAME_MAP;

            $element
                .find('input')
                .on('focus', (event) => {
                    this.toggleStateDropdown(true);
                })
                .on('blur', (event) => {
                    this.toggleStateDropdown(false);
                });
        };

        this.selectStateOption = (state: string) => {
            this.state = state;
            this.toggleStateDropdown(false);
        };

        this.toggleStateDropdown = (show: boolean) => {
            $timeout(() => (this.showStateOptions = show));
        };
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

import * as _ from 'lodash';
import { IAugmentedJQuery, ITimeoutService } from 'angular';

const module = angular.module('hippo-input', []);

interface HippoInputController extends angular.IStrictController {
    id: string;
    label: string;
    isFocused: boolean;
    name: string;
    required: string;
    readOnly: boolean;
    autocomplete: string;
}

module.component('hippoInput', {
    bindings: {
        label: '@',
        ngModel: '=',
        maxLength: '<?',
        required: '@',
        autocomplete: '@',
        readOnly: '<',
        onInputChange: '&',
        name: '@?',
        type: '@?'
    },
    template: template,
    controller: function(this: HippoInputController, $element: IAugmentedJQuery, $timeout: ITimeoutService) {
        this.$onInit = () => {
            this.id = _.kebabCase(this.label);
            this.name = this.name || _.camelCase(this.label);

            $timeout(() => {
                if (this.readOnly === true) {
                    $element.find('input').prop('disabled', true);
                }

                if (this.autocomplete === 'false') {
                    $element.find('input').attr('autocomplete', '__away');
                }

                if (this.required === 'true') {
                    $element.find('input').attr('required', '');
                }

                $element.find('input').on('focus', () => {
                    $timeout(() => {
                        this.isFocused = true;
                    });
                });

                $element.find('input').on('blur', () => {
                    $timeout(() => {
                        this.isFocused = false;
                    });
                });
            });
        };

        this.$onChanges = (onChangesObj) => {
            const readOnly = onChangesObj.readOnly;
            if (readOnly && readOnly.currentValue !== undefined) {
                if (readOnly.currentValue === true) {
                    $element.find('input').prop('disabled', true);
                } else {
                    $element.find('input').prop('disabled', false);
                }
            }
        };
    }
});

export { module };

import * as angular from 'angular';
import * as template from './template.html';
import * as hippoConstFactory from 'common/hippoConst.js';
import './styling.scss';

const module = angular.module('support-info', []);

interface SupportInfoController extends angular.IStrictController {
    title: string;
    icon: string;
    supportNumber: string;
    formatPhoneNumber: string;
}

module.component('supportInfo', {
    template: template,
    controller: function(this: SupportInfoController) {
        'ngInject';
        this.$onInit = () => {
            this.icon = 'chat-support';
            this.title = 'Support';
        };
    }
});

export { module };

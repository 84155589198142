import * as angular from 'angular';

import './styling.scss';

import * as EditPropertyAddressModal from './edit-property-address';
import * as EditMortgageClauseModal from './edit-mortgagee-clause';
import * as EditCreditCardContactSupportModal from './edit-credit-card-contact-support';
import * as EditEscrowDetailsModal from './edit-escrow-details';
import * as EditSecondInsuredModal from './edit-second-insured';
import * as BaseModal from 'common/source/modals/base-modal';
import * as ConfirmEOIUpdatesModal from './confirm-eoi-updates';
import * as EscrowFormComponent from 'common/source/components/escrow-form';
import * as ChangesUnderReviewModal from './changes-under-review';
import * as NeedCustomerApprovalModal from './need-customer-approval';
import * as FaxDocumentsModal from './fax-documents';

const module = angular.module('lender-portal-modals', [
    EditPropertyAddressModal.module.name,
    EditCreditCardContactSupportModal.module.name,
    EditEscrowDetailsModal.module.name,
    EditSecondInsuredModal.module.name,
    BaseModal.module.name,
    ConfirmEOIUpdatesModal.module.name,
    EditMortgageClauseModal.module.name,
    EscrowFormComponent.module.name,
    ChangesUnderReviewModal.module.name,
    NeedCustomerApprovalModal.module.name,
    FaxDocumentsModal.module.name
]);

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { getLongNumericAmericanDateString } from 'common/source/utilities/dates';

const module = angular.module('display-policy-info', []);

import * as _ from 'lodash';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { getFullName, isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';

interface DisplayPolicyInfoController extends angular.IStrictController {
    policyTitle: string;
    eoiTitle: string;
    statusString: string;
    isCancelled: boolean;
    cancellationReason: string;
    insuredNameString: string;
    carrierString: string;
    policyInfo: LenderPolicyInfo;
    borderType: string;
    policyNumber: PolicyNumber;
    lastUpdateString: string;
    lastUpdateDate: string;
    eoiDownloadUrl: string;
    cancellationType?: CancellationMessageType | 'Unknown';
}

// This would be shorter, but less precise.  Notice there are possibly undefined values, and a union type.
// interface ReasonsToCancellationType {
//     [key: string]: cancellationMessageType | undefined;
// }

interface ReasonsToCancellationType {
    new_business_escrow_payment_never_received: 'company';
    new_business_insured_request_flat: 'insured_request';
    new_business_company_request_flat: 'company';
    new_business_non_payment_of_premium: 'billing';
    new_business_void: 'company';
    new_business_policy_rescission: 'company';
    new_business_non_payment_of_initial_premium: 'billing';
    new_business_insured_request: 'insured_request';
    new_business_material_misrepresentation_of_a_claim: 'company';
    new_business_material_misrepresentation_of_the_application: 'company';
    new_business_claim_filed_within_the_underwriting_period: 'company';
    new_business_insured_request_moved_out_of_state: 'insured_request';
    new_business_insured_request_no_longer_owns_the_property: 'insured_request';
    new_business_insured_request_is_deceased: 'insured_request';
    new_business_substantial_increase_in_hazard: 'company';
    new_business_failure_to_allow_property_inspection: 'company';
    new_business_failure_to_provide_information_necessary_for_underwriting_or_rating: 'company';
    new_business_risk_is_unacceptable: 'company';
    new_business_terms_acceptance: 'terms_acceptance';
    new_business_free_form_text: 'company';
    mid_term_insured_request_flat: 'insured_request';
    mid_term_company_request_flat: 'company';
    mid_term_non_payment_of_premium: 'billing';
    mid_term_void: 'company';
    mid_term_policy_rescission: 'company';
    mid_term_insured_request: 'insured_request';
    mid_term_material_misrepresentation_of_a_claim: 'company';
    mid_term_material_misrepresentation_of_the_application: 'company';
    mid_term_substantial_increase_in_hazard: 'company';
    mid_term_free_form_text: 'company';
    renewal_pending_active_escrow_payment_never_received: 'company';
    renewal_pending_active_non_payment_of_premium: 'billing';
    renewal_pending_active_previous_term_cancelled: 'insured_request';
    renewal_pending_active_insured_request: 'insured_request';
}

module.component('displayPolicyInfo', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: DisplayPolicyInfoController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        $rootScope: angular.IRootScopeService
    ) {
        'ngInject';

        const reasonsToCancellationType: ReasonsToCancellationType = {
            new_business_escrow_payment_never_received: 'company',
            new_business_insured_request_flat: 'insured_request',
            new_business_company_request_flat: 'company',
            new_business_non_payment_of_premium: 'billing',
            new_business_void: 'company',
            new_business_policy_rescission: 'company',
            new_business_non_payment_of_initial_premium: 'billing',
            new_business_insured_request: 'insured_request',
            new_business_material_misrepresentation_of_a_claim: 'company',
            new_business_material_misrepresentation_of_the_application: 'company',
            new_business_claim_filed_within_the_underwriting_period: 'company',
            new_business_insured_request_moved_out_of_state: 'insured_request',
            new_business_insured_request_no_longer_owns_the_property: 'insured_request',
            new_business_insured_request_is_deceased: 'insured_request',
            new_business_substantial_increase_in_hazard: 'company',
            new_business_failure_to_allow_property_inspection: 'company',
            new_business_failure_to_provide_information_necessary_for_underwriting_or_rating: 'company',
            new_business_risk_is_unacceptable: 'company',
            new_business_terms_acceptance: 'terms_acceptance',
            new_business_free_form_text: 'company',
            mid_term_insured_request_flat: 'insured_request',
            mid_term_company_request_flat: 'company',
            mid_term_non_payment_of_premium: 'billing',
            mid_term_void: 'company',
            mid_term_policy_rescission: 'company',
            mid_term_insured_request: 'insured_request',
            mid_term_material_misrepresentation_of_a_claim: 'company',
            mid_term_material_misrepresentation_of_the_application: 'company',
            mid_term_substantial_increase_in_hazard: 'company',
            mid_term_free_form_text: 'company',
            renewal_pending_active_escrow_payment_never_received: 'company',
            renewal_pending_active_non_payment_of_premium: 'billing',
            renewal_pending_active_previous_term_cancelled: 'insured_request',
            renewal_pending_active_insured_request: 'insured_request'
        };

        function isKnownCancellationReason(reason: string): reason is keyof ReasonsToCancellationType {
            return Object.keys(reasonsToCancellationType).indexOf(reason) > -1;
        }

        this.$onInit = () => {
            const firstAndLastName = getFullName(this.policyInfo);
            this.policyTitle = 'Policy #';
            this.eoiTitle = 'EOI';
            this.statusString = `Policy Status: ${_.startCase(this.policyInfo.status)}`;
            this.insuredNameString = `Insured Name: ${firstAndLastName}`;
            this.carrierString = `Insurance Carrier: ${this.policyInfo.carrierName || this.policyInfo.carrier}`;
            this.lastUpdateString = 'Last Update:';
            this.lastUpdateDate = getLongNumericAmericanDateString(this.policyInfo.aggregate_documents.eoi!.created_at);
            this.eoiDownloadUrl = this.policyInfo.aggregate_documents.eoi!.url;
            this.policyNumber = this.policyInfo.policy_number;
            this.cancellationType = undefined;
            this.isCancelled = isPolicyCancelled(this.policyInfo);

            if (this.isCancelled && this.policyInfo.cancellation) {
                const cancellationReason = this.policyInfo.cancellation.reason;

                if (isKnownCancellationReason(cancellationReason)) {
                    this.cancellationType = reasonsToCancellationType[cancellationReason];
                } else {
                    this.cancellationType = 'Unknown'; // handle some other way?
                }

                if (this.policyInfo.status === 'active') {
                    this.statusString = `Policy Status: Pending Cancellation`;
                } else {
                    this.statusString = `Policy Status: Cancelled`;
                }
                if (this.cancellationType === 'company') {
                    this.cancellationReason = `Cancel reason: Hippo policy has been cancelled effective ${
                        this.policyInfo.cancellation.update_date
                    }.`;
                } else if (this.cancellationType === 'billing') {
                    this.cancellationReason = `Cancel reason: Hippo policy has been cancelled due to the missed payment of premium, cancellation effective ${
                        this.policyInfo.cancellation.update_date
                    }.`;
                } else if (this.cancellationType === 'insured_request') {
                    this.cancellationReason = `Cancel reason: Hippo policy has been cancelled by request of the insured, cancellation effective ${
                        this.policyInfo.cancellation.update_date
                    }.`;
                } else if (this.cancellationType === 'terms_acceptance') {
                    this.cancellationReason = `Cancel reason: Hippo policy has been cancelled due to missed terms acceptance within an appropriate timeframe, cancellation effective ${
                        this.policyInfo.cancellation.update_date
                    }.`;
                } else {
                    // Unknown case
                    this.cancellationReason = `Cancel reason: Hippo policy has been cancelled effective  ${
                        this.policyInfo.cancellation.update_date
                    }.`;
                }
            }
        };

        const showLenderPolicyInfoChangesApplied = () => {
            const updatedPolicyInfo = lenderPolicyInfoService.getPolicyInfo();

            if (
                updatedPolicyInfo &&
                updatedPolicyInfo.aggregate_documents.eoi &&
                updatedPolicyInfo.aggregate_documents.eoi.url
            ) {
                this.lastUpdateDate = getLongNumericAmericanDateString(
                    updatedPolicyInfo.aggregate_documents.eoi.created_at
                );
                this.eoiDownloadUrl = updatedPolicyInfo.aggregate_documents.eoi.url;
            }
        };

        const deregisterLenderPolicyInfoChangesAppliedListener = $rootScope.$on(
            'lenderPolicyInfoChangesApplied',
            showLenderPolicyInfoChangesApplied
        );

        this.$onDestroy = () => {
            deregisterLenderPolicyInfoChangesAppliedListener();
        };
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { getLongNumericAmericanDateString } from 'common/source/utilities/dates';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import {
    getFormattedExtendedReplacementCostCoverageAmount,
    getFormattedGeneralDeductibleAmount,
    getFormattedHomeCoverageAmount,
    getFormattedHurricaneDeductibleAmount,
    getFormattedLossOfUseCoverageAmount,
    getFormattedOrdinanceProtectionCoverageAmount,
    getFormattedSeparateStructureCoverageAmount,
    getFormattedWindDeductibleAmount,
    getFoundationAndWaterCoverageDisplay,
    getMineSubsidenceCoverageDisplay,
    getRoofCoverageDisplay,
    getSinkholeCollapseCoverageDisplay
} from 'common/source/utilities/policyInfoDisplay';

const module = angular.module('policyCoverage', []);

const addExtraCoverage = (policyInfo: LenderPolicyInfo, policyCoverage: Array<PolicyCoverage>) => {
    const extendedReplacementCostCoverageAmount = getFormattedExtendedReplacementCostCoverageAmount(policyInfo);
    if (extendedReplacementCostCoverageAmount) {
        policyCoverage.push({
            name: 'Extended Replacement Cost',
            value: extendedReplacementCostCoverageAmount
        });
    }
    const separateStructureCoverageAmount = getFormattedSeparateStructureCoverageAmount(policyInfo);
    if (separateStructureCoverageAmount) {
        policyCoverage.push({
            name: 'Separate Structure',
            value: separateStructureCoverageAmount
        });
    }
    const lossOfUseCoverageAmount = getFormattedLossOfUseCoverageAmount(policyInfo);
    if (lossOfUseCoverageAmount) {
        policyCoverage.push({
            name: 'Loss of Use',
            value: lossOfUseCoverageAmount
        });
    }
    const roofCoverageDisplay = getRoofCoverageDisplay(policyInfo);
    if (roofCoverageDisplay) {
        policyCoverage.push({
            name: 'Roof Surface Coverage',
            value: roofCoverageDisplay
        });
    }
    const ordinanceProtectionCoverageAmount = getFormattedOrdinanceProtectionCoverageAmount(policyInfo);
    if (ordinanceProtectionCoverageAmount) {
        policyCoverage.push({
            name: 'Ordinance Protection',
            value: ordinanceProtectionCoverageAmount
        });
    }
    const mineSubsidenceCoverageDisplay = getMineSubsidenceCoverageDisplay(policyInfo);
    if (mineSubsidenceCoverageDisplay) {
        policyCoverage.push({
            name: 'Mine Subsidence',
            value: mineSubsidenceCoverageDisplay
        });
    }
    const sinkholeCollapseCoverageDisplay = getSinkholeCollapseCoverageDisplay(policyInfo);
    if (sinkholeCollapseCoverageDisplay) {
        policyCoverage.push({
            name: 'Sinkhole Collapse',
            value: sinkholeCollapseCoverageDisplay
        });
    }
    const foundationAndWaterCoverageDisplay = getFoundationAndWaterCoverageDisplay(policyInfo);
    if (foundationAndWaterCoverageDisplay) {
        policyCoverage.push({
            name: 'Foundation and Water Coverage',
            value: foundationAndWaterCoverageDisplay
        });
    }
};

const getValuationDoc = (documents: AggregateDocuments) => {
    if (Array.isArray(documents.valuation)) {
        if (documents.valuation.length) {
            return documents.valuation[documents.valuation.length - 1];
        }
    } else {
        return documents.valuation;
    }
};

interface CoverageController extends angular.IStrictController {
    title: string;
    icon: string;
    isShowingAllCoverage: boolean;
    policyDocuments: Array<PolicyDocument>;
    policyCoverage: Array<PolicyCoverage>;
    policyInfo: LenderPolicyInfo;
    openFaxModal: () => void;
    toggleShowMore: () => void;
}

module.component('policyCoverage', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: CoverageController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        $rootScope: angular.IRootScopeService,
        modalService: ModalService
    ) {
        'ngInject';

        const updateDisplay = () => {
            updateDocuments();
            updateCoverage();
        };
        const updateCoverage = () => {
            this.policyCoverage = [];
            this.policyCoverage.push({
                name: this.policyInfo.product === 'ho6' ? 'Condo Damage Coverage' : 'Home Rebuilding Coverage',
                value: getFormattedHomeCoverageAmount(this.policyInfo) || ''
            });
            this.policyCoverage.push({
                name: 'General Deductible',
                value: getFormattedGeneralDeductibleAmount(this.policyInfo) || ''
            });
            const windDeductibleAmount = getFormattedWindDeductibleAmount(this.policyInfo);
            if (windDeductibleAmount) {
                this.policyCoverage.push({
                    name: 'Wind/Hail Deductible',
                    value: windDeductibleAmount
                });
            }
            const hurricaneDeductibleAmount = getFormattedHurricaneDeductibleAmount(this.policyInfo);
            if (hurricaneDeductibleAmount) {
                this.policyCoverage.push({
                    name: 'Hurricane Deductible',
                    value: hurricaneDeductibleAmount
                });
            }
            if (this.isShowingAllCoverage) {
                // moved outside function as otherwise linter complains of:
                // This function has 156 lines, which is greater than the 150 lines authorized. Split it into smaller functions.
                addExtraCoverage(this.policyInfo, this.policyCoverage);
            }
        };
        const updateDocuments = () => {
            const documents: AggregateDocuments = this.policyInfo.aggregate_documents;
            this.policyDocuments = [];
            if (documents.eoi) {
                if (documents.eoi_only) {
                    this.policyDocuments.push({
                        name: `Evidence of Insurance (EOI) with Invoice`,
                        url: documents.eoi.url,
                        testId: 'eoi-document'
                    });
                } else {
                    this.policyDocuments.push({
                        name: `Evidence of Insurance (EOI)`,
                        url: documents.eoi.url,
                        testId: 'eoi-document'
                    });
                }
            }
            if (documents.escrow_invoice) {
                this.policyDocuments.push({
                    name: 'Mortgage Bill Invoice',
                    url: documents.escrow_invoice.url,
                    testId: 'invoice-document'
                });
            }
            if (documents.policy_invoice) {
                this.policyDocuments.push({
                    name: 'Policy Invoice',
                    url: documents.policy_invoice.url,
                    testId: 'policy-invoice'
                });
            }
            if (documents.declaration) {
                this.policyDocuments.push({
                    name: 'Policy Declaration',
                    url: documents.declaration.url,
                    testId: 'declaration'
                });
            }
            const valuationDoc = getValuationDoc(documents);
            if (valuationDoc) {
                this.policyDocuments.push({
                    name: 'Home Rebuilding Cost Estimate (RCE)',
                    url: valuationDoc.url,
                    testId: 'rce-document'
                });
            }
            if (documents.eoi && documents.eoi_only) {
                this.policyDocuments.push({
                    name: `Evidence of Insurance (EOI)`,
                    url: documents.eoi_only.url,
                    testId: 'eoi-document'
                });
            }
        };
        this.$onInit = () => {
            this.icon = 'documents-with-house';
            this.title = 'Coverage';
            this.isShowingAllCoverage = false;
            updateDisplay();
        };
        this.$onDestroy = () => {
            deregisterLenderPolicyInfoChangesAppliedListener();
        };
        const showLenderPolicyInfoChangesApplied = () => {
            const updatedPolicyInfo = lenderPolicyInfoService.getPolicyInfo();
            if (updatedPolicyInfo) {
                this.policyInfo = updatedPolicyInfo;
                updateDisplay();
            }
        };
        this.openFaxModal = () => {
            modalService.openCloseableModal('fax-documents');
        };
        this.toggleShowMore = () => {
            this.isShowingAllCoverage = !this.isShowingAllCoverage;
            updateDisplay();
        };
        // tslint:disable-next-line
        const deregisterLenderPolicyInfoChangesAppliedListener = $rootScope.$on(
            'lenderPolicyInfoChangesApplied',
            showLenderPolicyInfoChangesApplied
        );
    }
});

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';
import { IRootScopeService } from 'angular';

export const module = angular.module('lenderPolicyInfoService', []);

export class LenderPolicyInfoService {
    private policyInfo: LenderPolicyInfo | undefined;
    private $rootScope: IRootScopeService;

    constructor($rootScope: IRootScopeService) {
        this.$rootScope = $rootScope;
    }

    setPolicyInfo(policyInfo: LenderPolicyInfo, alreadySavedToBackend: boolean = false) {
        // 1. Case 1: No transaction and new policyInfo was saved to backend. (ChangesApplied)
        // 2. Case 2: No transaction and new policyInfo was not saved to backend. (UpdatedWithoutSaving)
        // 3. Case 3: Transaction and new policyInfo was saved to backend without name and email. (UpdatedWithoutSaving)
        // 4. Case 4: Transaction and new policyInfo was saved to backend with name and email. (UpdatedWithoutSaving)

        const transaction = policyInfo.transaction;
        const changesApplied = !transaction && alreadySavedToBackend;

        const emitEvent = `lenderPolicyInfo${changesApplied ? 'ChangesApplied' : 'UpdatedWithoutSaving'}`;
        this.policyInfo = policyInfo;
        this.$rootScope.$emit(emitEvent);
    }

    getPolicyInfo() {
        return _.cloneDeep(this.policyInfo);
    }
}

module.factory('lenderPolicyInfoService', function($rootScope: IRootScopeService) {
    'ngInject';

    return new LenderPolicyInfoService($rootScope);
});

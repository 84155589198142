import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { TransitionService } from '@uirouter/angularjs';
import { getLoginSref, getPolicySref } from 'components/navigation/shared/states';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('mobile-navigation', []);

interface MobileHeaderController extends angular.IComponentController {
    userIsLoggedIn: boolean;
}

module.component('mobileNavigation', {
    template: template,
    controller: function(
        this: MobileHeaderController,
        $transitions: TransitionService,
        authenticationService: AuthenticationService
    ) {
        this.$onInit = () => {
            this.getPolicySref = getPolicySref;
            this.getLoginSref = getLoginSref;
            this.open = () => (this.active = true);
            this.close = () => (this.active = false);
            this.logout = () => (this.active = false);
            this.userIsLoggedIn = authenticationService.isLoggedIn();
        };
    }
});

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';

const module = angular.module('invalid-zipcode', []);

module.directive('invalidZipcode', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(
            this: angular.IDirective,
            $scope: angular.IScope,
            $element: JQLite,
            attrs: angular.IAttributes,
            ctrl?: angular.IController
        ) {
            const zipcode5Regex = /^[0-9]{5}$/;
            const zipcode9Regex = /^[0-9]{9}$/;
            const zipcode10Regex = /^[0-9]{5}-[0-9]{4}$/;
            if (this.require === 'ngModel') {
                (ctrl as angular.INgModelController).$validators.invalidZipcode = (modelValue: any, viewValue: any) => {
                    if (!_.isEmpty(viewValue)) {
                        return (
                            !!zipcode5Regex.test(viewValue) ||
                            !!zipcode9Regex.test(viewValue) ||
                            !!zipcode10Regex.test(viewValue)
                        );
                    } else {
                        return false;
                    }
                };
            }
            $element.on('blur', function(event) {
                const val: string = $element.val();
                if (!!zipcode9Regex.test(val)) {
                    $element.val(val.substr(0, 5) + '-' + val.substr(5, 4));
                }
            });
        }
    };
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import { isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';

const module = angular.module('vesting-name', []);

interface VestingNameController extends angular.IStrictController {
    title: string;
    icon: string;
    isCancelled: boolean;
    onEdit: () => void;
    onSave: () => void;
    addSecondInsured: () => void;
    hasSecondInsured: () => boolean;
    policyInfo: LenderPolicyInfo;
    vestingName?: string;
    secondInsured?: SecondInsured;
}

interface VestingNameFormScope extends angular.IScope {
    vestingNameForm: angular.IFormController;
}

module.component('vestingName', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: VestingNameController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        modalService: ModalService,
        $rootScope: angular.IRootScopeService,
        $element: angular.IAugmentedJQuery
    ) {
        'ngInject';

        const updateDisplay = () => {
            this.vestingName = this.policyInfo.property_data.mortgages.legal_name_on_deed;
            this.secondInsured = this.policyInfo.personal_information.second_insured;
            this.isCancelled = isPolicyCancelled(this.policyInfo);
        };

        this.$onInit = () => {
            this.icon = 'person';
            this.title = 'Vesting Name';
            updateDisplay();
        };

        this.onSave = () => {
            this.policyInfo.property_data.mortgages.legal_name_on_deed = this.vestingName;
            lenderPolicyInfoService.setPolicyInfo(this.policyInfo);
        };

        this.onEdit = () => {
            this.vestingName = this.policyInfo.property_data.mortgages.legal_name_on_deed;
        };

        this.addSecondInsured = () => {
            modalService.openUncloseableModal('edit-second-insured');
        };

        this.hasSecondInsured = () => {
            return !!(this.secondInsured && (this.secondInsured.first_name || this.secondInsured.last_name));
        };

        const deregisterLenderPolicyInfoChangesApplied = $rootScope.$on('lenderPolicyInfoChangesApplied', () => {
            // Set form pristine after saving
            const form = $element.find('form').scope() as VestingNameFormScope;
            const vestingNameForm = form.vestingNameForm;
            vestingNameForm.$setPristine();
            updateDisplay();
        });

        this.$onDestroy = () => {
            deregisterLenderPolicyInfoChangesApplied();
        };
    }
});

export { module };

import * as angular from 'angular';

export function openUrlInNewTab(url: string) {
    const $window: angular.IWindowService = angular.injector(['ng']).get('$window');
    const newWindow = $window.open();

    if (newWindow) {
        (newWindow as any).opener = null;
        newWindow.location.href = url;
    }
}

import * as angular from 'angular';
import { HippoApiService } from 'services/hippoApi';
import { AuthenticationService } from 'services/authentication';
import { NavigationService } from 'services/navigation';

const module = angular.module('policy-state', []);

const resolve = {
    policyInfo: function(
        hippoApiService: HippoApiService,
        navigationService: NavigationService,
        authenticationService: AuthenticationService
    ) {
        'ngInject';

        if (authenticationService.isLoggedIn()) {
            const { policyNumber, zipcode, lastName } = authenticationService.getAuthenticationParameters();
            return hippoApiService.getPolicyInfo(policyNumber, zipcode, lastName);
        }

        return null;
    }
};

const onEnter = function() {};

const onExit = function() {};

const template = '<policy-page policy-info="policyInfo"></policy-page>';

interface PolicyPageContainerScope extends angular.IStrictController {
    policyInfo: LenderPolicyInfo;
}

const controller = function(
    $scope: PolicyPageContainerScope,
    policyInfo: LenderPolicyInfo,
    navigationService: NavigationService,
    authenticationService: AuthenticationService
) {
    'ngInject';

    $scope.policyInfo = policyInfo;

    if (!authenticationService.isLoggedIn()) {
        navigationService.toLoginPage('login');
    }
};

export { module, template, controller, resolve, onEnter, onExit };

import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';

const module = angular.module('title-section', []);

interface TitleSectionController extends angular.IStrictController {
    sectionTitle: string;
    borderType: string;
    isTranscludePresent: () => boolean;
}

module.component('titleSection', {
    template: template,
    bindings: {
        sectionTitle: '<',
        borderType: '<',
        isInline: '@'
    },
    transclude: true,
    controller: function(this: TitleSectionController, $element: angular.IAugmentedJQuery) {
        'ngInject';

        this.$onInit = () => {
            if (this.borderType === 'bottom') {
                $element.addClass('with-bottom-border');
            } else if (this.borderType === 'surround') {
                $element.addClass('with-surround-border');
            }
            if (this.sectionTitle) {
                $element.addClass('with-section-title');
            }
            this.isTranscludePresent = () => {
                return $element.find('ng-transclude').html() !== '';
            };
        };
    }
});

export { module };

import * as angular from 'angular';
import * as hippoConstFactory from 'common/hippoConst.js';
import './styling.scss';
import * as template from './template.html';
import { ModalService } from 'common/source/services/modal';

const module = angular.module('edit-credit-card-contact-support', []);

interface EditCreditCardContactSupportController extends angular.IStrictController {
    callSupportText: string;
    buttonText: string;
    gotIt: () => void;
    mainTitle: string;
    icon: string;
}

module.component('editCreditCardContactSupport', {
    template: template,
    controller: function(this: EditCreditCardContactSupportController, modalService: ModalService) {
        this.$onInit = () => {
            this.buttonText = 'Got it';
            this.mainTitle = 'Payment Method';
            this.icon = 'green-money';
        };

        this.gotIt = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';
import * as template from './template.html';
import 'angular-ui-mask';
import './styling.scss';
import { NavigationService } from 'services/navigation';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('login-panel', ['ui.mask']).run(function(uiMaskConfig: any) {
    uiMaskConfig.clearOnBlur = false;
});

interface LoginFormValidation {
    [key: string]: boolean;
    emptyPolicyNumber: boolean;
    invalidPolicyNumber: boolean;
    emptyLastName: boolean;
    emptyZipcode: boolean;
    invalidZipcode: boolean;
}

interface GetLenderPolicyInfoError {
    code: number;
    name: string;
    invalidErrors: string;
}

interface PolicyError {
    [key: string]: boolean;
    terminated: boolean;
    notFound: boolean;
}

class LoginPanelController {
    private $scope: angular.IScope;
    private navigationService: NavigationService;
    private authenticationService: AuthenticationService;
    private $timeout: angular.ITimeoutService;

    private inputs: NodeListOf<HTMLInputElement>;

    /* bindings */
    private policyInputTooltip: string;
    private policyNumber: string;
    private lastName: string;
    private zipcode: string;

    // Validations
    private loginFormValidation: LoginFormValidation;
    private policyError: PolicyError;

    private policyMask: string;

    private threeLetterMask: string;
    private fourLetterMask: string;

    private placeholderText: string;
    private get clearedPlaceHolderText(): string {
        return '';
    }
    private get editingPlaceHolderText(): string {
        if (this.policyMask === this.threeLetterMask) {
            return '   -       -  ';
        }
        return '    -       -  ';
    }

    private policyNumberPattern = /[a-z]{3,}-[0-9]{7,}-[0-9]{2,}$/i;
    private zipcodePattern = /^[0-9]{5}$/;

    static $inject = ['$scope', 'navigationService', 'authenticationService', '$timeout'];

    constructor(
        $scope: angular.IScope,
        navigationService: NavigationService,
        authenticationService: AuthenticationService,
        $timeout: angular.ITimeoutService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.navigationService = navigationService;
        this.authenticationService = authenticationService;
        this.policyInputTooltip = `
            Accepted policy numbers:<br>
            xxx-xxxxxxx-xx<br>
            xxx-xxxxxxxx-xx<br>
            xxxx-xxxxxxx-xx</br>
            xxxx-xxxxxxxx-xx<br>
        `;

        this.threeLetterMask = 'AAA-9999999-99';
        this.fourLetterMask = 'AAAA-9999999-99';
        this.policyMask = '';
        this.placeholderText = this.clearedPlaceHolderText;
        // Form inputs
        this.policyNumber = '';
        this.lastName = '';
        this.zipcode = '';
        // Validation
        this.loginFormValidation = {
            emptyPolicyNumber: false,
            invalidPolicyNumber: false,
            emptyLastName: false,
            emptyZipcode: false,
            invalidZipcode: false
        };
        // Policy Errors
        this.policyError = {
            terminated: false,
            notFound: false
        };

        // Submit on Enter key
        this.inputs = document.querySelectorAll('input');
        $timeout(() => {
            // Required to attach listener after render
            this.inputs = document.querySelectorAll('input');
            for (let i = 0; i < this.inputs.length; i++) {
                const element = this.inputs.item(i);
                element.addEventListener('keypress', this.handleEnter.bind(this));
            }
        });

        this.$scope.$on('$destroy', () => {
            this.inputs = document.querySelectorAll('input');
            for (let i = 0; i < this.inputs.length; i++) {
                const element = this.inputs.item(i);
                element.removeEventListener('keypress', this.handleEnter);
            }
        });
    }

    handleEnter(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // Timeout required to show validation
            this.$timeout(() => this.validateForm());
        }
    }

    handleBlur() {
        if (this.policyNumber) {
            this.policyNumber = this.policyNumber.toUpperCase();
        }
    }

    handleFocus() {
        const rawVal = this.policyNumber.replace(/\s|-/g, '');
        if (!this.policyMask) {
            this.policyMask = this.fourLetterMask;
        }
        if (!rawVal) {
            this.placeholderText = this.editingPlaceHolderText;
        }
    }

    handlePolicyInput($event: JQueryEventObject) {
        const value = this.policyNumber.replace(/\s|-/g, '');
        const len = value.length;
        if (len === 3) {
            if (isNaN(+$event.key)) {
                this.policyMask = this.fourLetterMask;
            } else {
                this.policyMask = this.threeLetterMask;
                this.policyNumber += $event.key;
            }
        } else if (len < 3) {
            this.policyMask = this.fourLetterMask;
        }
        this.placeholderText = this.editingPlaceHolderText;
        this.policyNumber = this.policyNumber.toUpperCase();
    }

    handlePaste($event: ClipboardEvent) {
        // IE places the clipboardData on the window
        const pasteValue = ($event.clipboardData || window.clipboardData)
            .getData('Text')
            .replace(/-/g, '')
            .trim();
        if (pasteValue.length >= 12) {
            const formatted = pasteValue.replace(/.*?(\w{3,4})(\d{7})(\d{2}).*/, '$1-$2-$3');
            this.policyMask = !formatted.match(/^[A-Za-z]{4}/) ? this.threeLetterMask : this.fourLetterMask;
            this.policyNumber = formatted;
        }
    }

    backButtonClick() {
        this.resetValidations(this.policyError);
    }

    resetValidations(validations: LoginFormValidation | PolicyError) {
        for (const key of Object.keys(validations)) {
            validations[key] = false;
        }
    }

    validate(validations: LoginFormValidation | PolicyError) {
        const keys = Object.keys(validations);
        return keys.every((k: string) => validations[k] === false);
    }

    updateZipcode() {
        // Ensure max length 5 and only numbers
        const zipcodeFilter = this.zipcode.replace(/[a-z]/gi, '').substring(0, 5);
        this.zipcode = zipcodeFilter;
    }

    validateForm() {
        this.resetValidations(this.loginFormValidation);
        const validations = this.loginFormValidation;
        // Policy Number
        if (!this.policyNumber) {
            validations.emptyPolicyNumber = true;
        } else if (!this.policyNumber.match(this.policyNumberPattern)) {
            validations.invalidPolicyNumber = true;
        }
        // Last Name
        if (!this.lastName) {
            validations.emptyLastName = true;
        }
        // Zipcode
        if (!this.zipcode) {
            validations.emptyZipcode = true;
        } else if (!this.zipcode.match(this.zipcodePattern)) {
            validations.invalidZipcode = true;
        }
        if (this.validate(this.loginFormValidation)) {
            this.login(this.policyNumber, this.zipcode, this.lastName);
        }
    }

    login(policyNumber: string, zipcode: string, lastName: string) {
        this.authenticationService
            .login(policyNumber, zipcode, lastName)
            .then(
                () => {
                    this.navigationService.toPolicyPage();
                },
                (result: GetLenderPolicyInfoError) => {
                    const errorMessage = _.get(result, 'data.invalidErrors');
                    if (errorMessage === 'Invalid Status: terminated') {
                        this.policyError.terminated = true;
                    } else {
                        this.policyError.notFound = true;
                    }
                }
            )
            .catch(() => {
                this.policyError.notFound = true;
            });
    }
}

module.component('loginPanel', {
    template: template,
    transclude: true,
    bindings: {
        attemptedLogin: '<?',
        loginAttemptSucceeded: '<?',
        loginAttemptFailed: '<?',
        unknownLoginError: '<?'
    },
    controller: LoginPanelController
});

export { module };

module.exports = () => {
    return {
        HIPPO_SUPPORT_PHONE_NUMBER: '1-800-585-0705',
        HIPPO_SALES_PHONE_NUMBER: '1-877-838-8866',
        HIPPO_CLAIMS_PHONE_NUMBER: '1-855-999-9746',
        HIPPO_SUPPORT_EMAIL: 'support@myhippo.com',
        HIPPO_NEW_SUPPORT_EMAIL: 'support@hippo.com',
        NOTION_SUPPORT_EMAIL: 'support@getnotion.com',
        NOTION_PHONE_NUMBER: '1-877-668-4660',
        KANGAROO_SUPPORT_EMAIL: 'support@heykangaroo.com',
        KANGAROO_PHONE_NUMBER: '1-800-371-0097',
        SIMPLISAFE_SUPPORT_EMAIL: 'support@simplisafe.com',
        SIMPLISAFE_PHONE_NUMBER: '1-800-548-9508',
        PAYMENT_METHOD: {
            CREDIT_CARD_DEBIT: 'cc',
            ESCROW: 'escrow',
            ACH: 'ach'
        },
        INPUT_MAX_LENGTH: 256,
        PAYMENT_ADDRESSES_BY_CARRIER: {
            spinnaker: {
                name: 'Hippo Insurance - Spinnaker Trust',
                line1: 'PO Box 842136',
                city: 'Dallas',
                state: 'TX',
                zip: '75284-2136'
            },
            topa: {
                name: 'Hippo Insurance - Topa Trust',
                line1: 'PO Box 842145',
                city: 'Dallas',
                state: 'TX',
                zip: '75284-2145'
            },
            canopius: {
                name: 'Hippo Insurance - Canopius Trust',
                line1: 'PO Box 842128',
                city: 'Dallas',
                state: 'TX',
                zip: '75284-2128'
            }
        },
        OVERNIGHT_PAYMENT_ADDRESSES_BY_CARRIER: {
            spinnaker: {
                name: 'Hippo Insurance - Spinnaker Trust',
                line1: 'Lockbox 842136',
                line2: '1950 N Stemmons Frwy',
                line3: 'Ste 5010',
                city: 'Dallas',
                state: 'TX',
                zip: '75207'
            },
            topa: {
                name: 'Hippo Insurance - Topa Trust',
                line1: 'Lockbox 842145',
                line2: '1950 N Stemmons Frwy',
                line3: 'Ste 5010',
                city: 'Dallas',
                state: 'TX',
                zip: '75207'
            },
            canopius: {
                name: 'Hippo Insurance - Canopius Trust',
                line1: 'Lockbox 842128',
                line2: '1950 N Stemmons Frwy',
                line3: 'Ste 5010',
                city: 'Dallas',
                state: 'TX',
                zip: '75207'
            }
        },
        FAQ_URL: 'http://hippo.com/faq',
        HIPPO_HOME_CARE_URL: 'https://www.hippohomecare.com/?utm_source=hippo&utm_medium=customerportal&utm_campaign=rightrail'
    };
};

import * as angular from 'angular';
import * as _ from 'lodash';
import * as template from './template.html';
import 'angular-ui-mask';
import './styling.scss';
import { NavigationService } from 'services/navigation';
import { HippoApiService } from 'services/hippoApi';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('login-by-address-panel', ['ui.mask']).run(function(uiMaskConfig: any) {
    uiMaskConfig.clearOnBlur = false;
});

interface LoginByAddressFormValidation {
    [key: string]: boolean;
    emptyPolicyNumber: boolean;
    invalidPolicyNumber: boolean;
    emptyPassword: boolean;
}

interface GetLenderPolicyInfoError {
    code: number;
    name: string;
    invalidErrors: string;
}

interface PolicyError {
    [key: string]: boolean;
    terminated: boolean;
    notFound: boolean;
}

interface AutocompleteSelection {
    originalObject: {
        name: string;
    };
}

type FocusableFields = 'cty' | 'strt';

// ** IMPORTANT **
// City (cty), Street (strt), Zipcode (thezp), State (ast)
// are intentionally mispelled to prevent chrome from showing autocompletion
class LoginByAddressPanelController {
    private $scope: angular.IScope;
    private navigationService: NavigationService;
    private authenticationService: AuthenticationService;
    private hippoApiService: HippoApiService;
    private $timeout: angular.ITimeoutService;
    private $element: angular.IAugmentedJQuery;

    private inputs: NodeListOf<HTMLInputElement>;

    /* bindings */
    private policyInputTooltip: string;
    private password: string;
    private lastName: string;
    private street: string;
    private city: string;
    private state: string;
    private zipcode: string;

    // Validations
    private loginByAddressFormValidation: LoginByAddressFormValidation;
    private policyError: PolicyError;

    private policyMask: string;

    private threeLetterMask: string;
    private fourLetterMask: string;

    private placeholderText: string;
    private showFullForm: boolean;
    private get clearedPlaceHolderText(): string {
        return '';
    }
    private get editingPlaceHolderText(): string {
        if (this.policyMask === this.threeLetterMask) {
            return '   -       -  ';
        }
        return '    -       -  ';
    }

    private policyNumberPattern = /[a-z]{3,4}-[0-9]{7}-[0-9]{2}$/i;
    private zipcodePattern = /^[0-9]{5}$/;

    static $inject = [
        '$scope',
        '$element',
        'navigationService',
        'authenticationService',
        'hippoApiService',
        '$timeout'
    ];

    constructor(
        $scope: angular.IScope,
        $element: angular.IAugmentedJQuery,
        navigationService: NavigationService,
        authenticationService: AuthenticationService,
        hippoApiService: HippoApiService,
        $timeout: angular.ITimeoutService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$element = $element;
        this.navigationService = navigationService;
        this.authenticationService = authenticationService;
        this.hippoApiService = hippoApiService;
        this.policyInputTooltip = 'Accepted policy numbers:<br> xxx-xxxxxxx-xx or xxxx-xxxxxxx-xx';

        this.threeLetterMask = 'AAA-9999999-99';
        this.fourLetterMask = 'AAAA-9999999-99';
        this.policyMask = '';
        this.placeholderText = this.clearedPlaceHolderText;
        this.showFullForm = false;

        // Form inputs
        this.password = '';
        this.lastName = '';
        this.street = '';
        this.city = '';
        this.state = '';
        this.zipcode = '';

        // Validation
        this.loginByAddressFormValidation = {
            emptyPolicyNumber: false,
            emptyPassword: false,
            invalidPolicyNumber: false,
            invalidPassword: false
        };
        // Policy Errors
        this.policyError = {
            terminated: false,
            notFound: false
        };

        // Submit on Enter key
        this.inputs = document.querySelectorAll('input');
        $timeout(() => {
            // Required to attach listener after render
            this.inputs = document.querySelectorAll('input');
            for (let i = 0; i < this.inputs.length; i++) {
                const element = this.inputs.item(i);
                element.setAttribute('autocomplete', 'off');
                element.addEventListener('keypress', this.handleEnter.bind(this));
            }
        });

        this.$scope.$watch(() => {
            const hasNameAndPassword = this.lastName && this.password;
            const mainForm = document.querySelector('.main-form');
            if (hasNameAndPassword && mainForm) {
                this.showFullForm = true;
            }
        });

        this.$scope.$on('$destroy', () => {
            this.inputs = document.querySelectorAll('input');
            for (let i = 0; i < this.inputs.length; i++) {
                const element = this.inputs.item(i);
                element.removeEventListener('keypress', this.handleEnter);
            }
        });
        this.updateCity = this.updateCity.bind(this);
        this.updateStreet = this.updateStreet.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.selectStreet = this.selectStreet.bind(this);
        this.getMatchingCities = this.getMatchingCities.bind(this);
        this.getMatchingStreets = this.getMatchingStreets.bind(this);
    }

    handleEnter(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // Timeout required to show validation
            this.$timeout(() => this.validateForm());
        }
    }

    // Simulate material label animation for input
    focusInput(id: FocusableFields) {
        const container = document.querySelector(`#${id}`);
        if (container) {
            container.classList.add('focused');
        }
    }

    // Simulate material label animation for blur
    blurInput(id: FocusableFields) {
        const container = document.querySelector(`#${id}`);
        if (container) {
            container.classList.remove('focused');
            const values = this.getFormValues();
            if (values[id === 'strt' ? 'street' : 'city']) {
                container.classList.add('has-value');
            } else {
                container.classList.remove('has-value');
            }
        }
    }

    backButtonClick() {
        this.resetValidations(this.policyError);
    }

    resetValidations(validations: LoginByAddressFormValidation | PolicyError) {
        for (const key of Object.keys(validations)) {
            validations[key] = false;
        }
    }

    validate(validations: LoginByAddressFormValidation | PolicyError) {
        const keys = Object.keys(validations);
        return keys.every((k: string) => validations[k] === false);
    }

    validateForm() {
        this.resetValidations(this.loginByAddressFormValidation);
        const validations = this.loginByAddressFormValidation;

        if (!this.password) {
            validations.emptyPassword = true;
        }

        if (this.validate(this.loginByAddressFormValidation)) {
            this.login();
        }
    }

    getFormValues() {
        return {
            password: this.password,
            last_name: this.lastName,
            street: this.street,
            city: this.city,
            state: this.state,
            zip: this.zipcode
        };
    }

    updateCity(city: string) {
        this.city = city;
    }

    selectCity(selection: AutocompleteSelection) {
        if (selection && selection.originalObject) {
            const { name } = selection.originalObject;
            this.city = name;
        }
    }

    updateStreet(street: string) {
        this.street = street;
    }

    selectStreet(selection: AutocompleteSelection) {
        if (selection && selection.originalObject) {
            const { name } = selection.originalObject;
            this.street = name;
        }
    }

    getMatchingStreets() {
        const { last_name, street, password } = this.getFormValues();
        return this.hippoApiService.searchMatchingCityStreet({ last_name, street, password, type: 'street' });
    }

    getMatchingCities() {
        const { last_name, city, password } = this.getFormValues();
        return this.hippoApiService.searchMatchingCityStreet({ last_name, city, password, type: 'city' });
    }

    login() {
        const formValues = this.getFormValues();
        this.authenticationService
            .loginByAddress(formValues)
            .then(
                () => {
                    this.navigationService.toPolicyPage();
                },
                (result: GetLenderPolicyInfoError) => {
                    const errorMessage = _.get(result, 'data.invalidErrors');
                    if (errorMessage === 'Invalid Status: terminated') {
                        this.policyError.terminated = true;
                    } else {
                        this.policyError.notFound = true;
                    }
                }
            )
            .catch(() => {
                this.policyError.notFound = true;
            });
    }
}

module.component('loginByAddressPanel', {
    template: template,
    transclude: true,
    bindings: {
        attemptedLogin: '<?',
        loginAttemptSucceeded: '<?',
        loginAttemptFailed: '<?',
        unknownLoginError: '<?'
    },
    controller: LoginByAddressPanelController
});

export { module };

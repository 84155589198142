import * as angular from 'angular';
import './styling.scss';

const module = angular.module('hippo-icon', []);

interface HippoIconController extends angular.IStrictController {
    icon: string;
    size: string;
}

module.component('hippoIcon', {
    bindings: {
        icon: '@',
        size: '@'
    },
    controller: function(this: HippoIconController, $element: angular.IAugmentedJQuery) {
        this.$onInit = () => {
            // As needed, we can transform the name of the icon to a standard name.
            // We can also throw errors if someone uses an icon that doesn't exist
            addIconAndSize();
        };
        this.$onChanges = (onChangesObj) => {
            const icon = onChangesObj.icon;
            if (icon && icon.previousValue !== undefined && typeof icon.previousValue === 'string') {
                $element.removeClass(icon.previousValue);
                addIconAndSize();
            }
        };

        const addIconAndSize = () => $element.addClass(this.icon).addClass(this.size);
    }
});

export { module };

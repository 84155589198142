import * as angular from 'angular';

import { IDialogService } from 'ng-dialog';
import fundingType = stripe.fundingType;

export const module = angular.module('modalService', []);

export class ModalService {
    private ngDialog: IDialogService;

    constructor(ngDialog: IDialogService) {
        this.ngDialog = ngDialog;
    }

    closeAllDialogs(value?: any) {
        this.ngDialog.closeAll(value);
    }

    openUncloseableModal(template: string, data: { [key: string]: any } = {}) {
        return this.ngDialog.open({
            closeByEscape: false,
            showClose: false,
            data: data,
            closeByDocument: false,
            closeByNavigation: true,
            template: `<${template} data="ngDialogData"></${template}>`
        });
    }

    openCloseableModal(template: string, data: { [key: string]: any } = {}) {
        return this.ngDialog.open({
            closeByEscape: true,
            showClose: true,
            data: data,
            closeByDocument: true,
            closeByNavigation: true,
            template: `<${template} data="ngDialogData"></${template}>`
        });
    }

    openFullPageModal(template: string, data: { [key: string]: any } = {}) {
        return this.ngDialog.open({
            closeByEscape: true,
            showClose: false,
            data: data,
            closeByDocument: true,
            closeByNavigation: true,
            className: 'full-page-modal',
            template: `<${template} data="ngDialogData"></${template}>`
        });
    }

    openFullPageModalWithPreCloseCallback(
        template: string,
        preCloseCallback: () => false | void,
        data: { [p: string]: any } = {}
    ) {
        return this.ngDialog.open({
            closeByEscape: true,
            showClose: false,
            data: data,
            closeByDocument: true,
            closeByNavigation: true,
            className: 'full-page-modal',
            template: `<${template} data="ngDialogData"></${template}>`,
            preCloseCallback: preCloseCallback
        });
    }
}

module.factory('modalService', function(ngDialog: IDialogService) {
    'ngInject';

    return new ModalService(ngDialog);
});

import * as angular from 'angular';

const module = angular.module('number-only', []);

module.directive('numberOnly', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(
            this: angular.IDirective,
            $scope: angular.IScope,
            $element: JQLite,
            attrs: angular.IAttributes,
            ctrl?: angular.IController
        ) {
            if (this.require === 'ngModel') {
                (ctrl as angular.INgModelController).$parsers.push(function(inputValue) {
                    if (inputValue === undefined) {
                        return '';
                    }
                    const transformedInput = inputValue.replace(/[^0-9]/g, '');
                    if (ctrl && transformedInput !== inputValue) {
                        ctrl.$setViewValue(transformedInput);
                        ctrl.$render();
                    }
                    return transformedInput;
                });
            }
        }
    };
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { IRootScopeService } from 'angular';

import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { diff, Diff } from 'deep-diff';
import { ModalService } from 'common/source/services/modal';
import { openUrlInNewTab } from 'common/source/utilities/windowHelper';
import { getEvidenceOfInsuranceDocument } from 'common/source/utilities/policyInfoHelper';

const module = angular.module('eoi-updates', []);

type ModifiedFieldDisplayName =
    | 'Mortgagee Clause'
    | 'Effective Date'
    | 'Property Address'
    | 'Payment Method'
    | 'Second Insured'
    | 'Vesting Name';

interface FieldNames {
    mortgageeClause: ModifiedFieldDisplayName;
    vestingName: ModifiedFieldDisplayName;
    effectiveDate: ModifiedFieldDisplayName;
    propertyAddress: ModifiedFieldDisplayName;
    paymentMethod: ModifiedFieldDisplayName;
    secondInsured: ModifiedFieldDisplayName;
}

interface EOIUpdatesController extends angular.IStrictController {
    modifiedPolicyInfoFields: Array<ModifiedFieldDisplayName>;
    applyChanges: () => void;
    changesApplied: boolean;
    downloadEOI: () => void;
    getLenderFirstName: () => string;
    hasPendingChanges: boolean;
    transaction?: TransactionChanges;
}

interface PathToFieldName {
    [key: string]: ModifiedFieldDisplayName;
}

module.component('eoiUpdates', {
    template: template,
    // tslint:disable-next-line
    controller: function(
        this: EOIUpdatesController,
        $rootScope: IRootScopeService,
        $scope: angular.IScope,
        lenderPolicyInfoService: LenderPolicyInfoService,
        $element: JQLite,
        modalService: ModalService,
        $window: angular.IWindowService
    ) {
        'ngInject';

        let lenderPolicyInfo: LenderPolicyInfo | undefined;

        const makeComponentSticky = () => {
            const EOI_UPDATES_CONTAINER_Y_OFFSET = 137;
            const APP_FOOTER_AND_EOI_UPDATES_CONTAINER_SPACING = 50;
            const STICKY_CLASS = 'sticky';
            const eoiUpdatesContainerHeight = $element[0].clientHeight;
            const appFooter = document.querySelector('app-footer') as HTMLElement;
            const appFooterOffsetTop = appFooter.offsetTop;

            if (
                $window.pageYOffset > EOI_UPDATES_CONTAINER_Y_OFFSET &&
                $window.pageYOffset + eoiUpdatesContainerHeight <
                    appFooterOffsetTop - APP_FOOTER_AND_EOI_UPDATES_CONTAINER_SPACING
            ) {
                $element.addClass(STICKY_CLASS);
            } else {
                $element.removeClass(STICKY_CLASS);
            }
        };

        this.$onInit = () => {
            lenderPolicyInfo = lenderPolicyInfoService.getPolicyInfo();
            showLenderPolicyInfoChangesUpdatedWithoutSaving();
            // casting to any because angular.element doesn't accept $window, but this is a valid use
            angular.element($window as any).on('scroll', makeComponentSticky);
        };

        this.applyChanges = () => {
            modalService.openUncloseableModal('confirm-eoi-updates');
        };

        this.downloadEOI = () => {
            if (lenderPolicyInfo) {
                const eoiUrl = getEvidenceOfInsuranceDocument(lenderPolicyInfo);
                if (eoiUrl) {
                    openUrlInNewTab(eoiUrl);
                }
            }
        };

        this.getLenderFirstName = () => {
            // Product team is aware this isn't a super robust way of getting the first name and are ok with it
            if (this.transaction && this.transaction.lender_name) {
                return this.transaction.lender_name.split(' ')[0];
            } else {
                return '';
            }
        };

        const fieldNames: FieldNames = {
            mortgageeClause: 'Mortgagee Clause',
            vestingName: 'Vesting Name',
            effectiveDate: 'Effective Date',
            propertyAddress: 'Property Address',
            paymentMethod: 'Payment Method',
            secondInsured: 'Second Insured'
        };

        const LENDER_POLICY_INFO_PROPERTY_PATH_TO_FIELD_NAME_MAP: PathToFieldName = {
            'property_data.mortgages.legal_name_on_deed': fieldNames.vestingName,
            effective_date: fieldNames.effectiveDate,
            'property_data.mortgages.details': fieldNames.mortgageeClause, // Detect new lenders
            'property_data.address.city': fieldNames.propertyAddress,
            'checkout_data.lender_name': fieldNames.paymentMethod,
            'checkout_data.loan_number': fieldNames.paymentMethod,
            'checkout_data.lender_mailing_address.zip': fieldNames.paymentMethod,
            'checkout_data.lender_mailing_address.city': fieldNames.paymentMethod,
            'checkout_data.lender_mailing_address.state': fieldNames.paymentMethod,
            'checkout_data.lender_mailing_address.street': fieldNames.paymentMethod,
            'checkout_data.lender_mailing_address.street2': fieldNames.paymentMethod,
            'personal_information.second_insured': fieldNames.secondInsured,
            'personal_information.second_insured.first_name': fieldNames.secondInsured,
            'personal_information.second_insured.middle_name': fieldNames.secondInsured,
            'personal_information.second_insured.last_name': fieldNames.secondInsured,
            'personal_information.second_insured.date_of_birth': fieldNames.secondInsured
        };

        // Map all properties of mortgages to Mortgageee Clause
        // Detect changes in existing lenders
        const mortgagePositions = ['0', '1', '2'];
        const mortgageProperties = ['name', 'street', 'street2', 'number', 'city', 'state', 'zip'];
        mortgagePositions.forEach((position) => {
            mortgageProperties.forEach((prop) => {
                const fieldNamePrefix = 'property_data.mortgages.details';
                const fullFieldName = `${fieldNamePrefix}.${position}.${prop}`;
                const mappedValue = fieldNames.mortgageeClause;
                LENDER_POLICY_INFO_PROPERTY_PATH_TO_FIELD_NAME_MAP[fullFieldName] = mappedValue;
            });
        });

        const showLenderPolicyInfoChangesUpdatedWithoutSaving = () => {
            this.changesApplied = false;
            this.modifiedPolicyInfoFields = [];
            const updatedLenderPolicyInfo = lenderPolicyInfoService.getPolicyInfo();
            if (updatedLenderPolicyInfo && lenderPolicyInfo) {
                const changes = diff(lenderPolicyInfo, updatedLenderPolicyInfo);

                if (changes && changes.length > 0) {
                    changes.forEach((value: Diff<LenderPolicyInfo, LenderPolicyInfo>) => {
                        if (!value || !value.path) {
                            return;
                        } else {
                            const fullPropertyPath = value.path.join('.');
                            const modifiedFieldName =
                                LENDER_POLICY_INFO_PROPERTY_PATH_TO_FIELD_NAME_MAP[fullPropertyPath];

                            if (modifiedFieldName && this.modifiedPolicyInfoFields.indexOf(modifiedFieldName) === -1) {
                                this.modifiedPolicyInfoFields.push(modifiedFieldName);
                            }
                        }
                    });
                }
            }

            if (updatedLenderPolicyInfo && updatedLenderPolicyInfo.transaction) {
                this.transaction = updatedLenderPolicyInfo.transaction;
                const transactionChanges = this.transaction.updated_fields;
                const lenderName = this.transaction.lender_name;
                const lenderEmail = this.transaction.lender_email;
                if (!lenderName || !lenderEmail) {
                    $element.addClass('ng-hide');
                } else if (transactionChanges) {
                    const mapped = transactionChanges.split(', ').map((val) => {
                        if (val === 'Legal Name On Deed') {
                            return 'Vesting Name';
                        } else if (val === 'City') {
                            return 'Property Address';
                        } else if (val === 'Mortgage Details') {
                            return 'Mortgage Clause';
                        }
                        return val;
                    });
                    const pendingChanges = mapped as Array<ModifiedFieldDisplayName>;
                    this.modifiedPolicyInfoFields = pendingChanges;
                    this.hasPendingChanges = true;
                    $element.removeClass('ng-hide');
                }
            } else if (this.modifiedPolicyInfoFields.length === 0) {
                $element.addClass('ng-hide');
            } else {
                $element.removeClass('ng-hide');
            }
        };

        const showLenderPolicyInfoChangesApplied = () => {
            lenderPolicyInfo = lenderPolicyInfoService.getPolicyInfo();
            this.changesApplied = true;
        };

        const deregisterLenderPolicyInfoUpdatedWithoutSavingListener = $rootScope.$on(
            'lenderPolicyInfoUpdatedWithoutSaving',
            showLenderPolicyInfoChangesUpdatedWithoutSaving
        );
        const deregisterLenderPolicyInfoChangesAppliedListener = $rootScope.$on(
            'lenderPolicyInfoChangesApplied',
            showLenderPolicyInfoChangesApplied
        );

        $scope.$on('$destroy', () => {
            deregisterLenderPolicyInfoUpdatedWithoutSavingListener();
            deregisterLenderPolicyInfoChangesAppliedListener();
            // casting to any because angular.element doesn't accept $window, but this is a valid use
            angular.element($window as any).off('wheel', makeComponentSticky);
        });
    }
});

export { module };

import * as angular from 'angular';

import './styling.scss';
import * as template from './template.html';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('logout-page', []);

interface LogoutPageController extends angular.IStrictController {}

module.component('logoutPage', {
    template: template,
    bindings: {},
    controller: function(
        this: LogoutPageController,
        $element: angular.IAugmentedJQuery,
        authenticationService: AuthenticationService
    ) {
        // TODO: possibly also do this in an iframe and detect success rather than redirecting the user
        // in theory we could open a hidden iframe that logs the user out, then posts a message saying it has done so
        // so we then show a result in the outer frame

        authenticationService.logout();
    }
});

export { module };

import { capitalizeWords, formatAsDollarsWithoutZeroCents } from 'common/source/utilities/strings';
import {
    getBelongingsCoverageAmount,
    getExtendedReplacementCostCoverageAmount,
    getFoundationAndWaterCoverageAmount,
    getGeneralDeductibleAmount,
    getHomeCoverageAmount,
    getHurricaneDeductibleAmount,
    getLossOfUseCoverageAmount,
    getMedicalPaymentCoverageAmount,
    getMineSubsidenceCoverage,
    getOrdinanceProtectionCoverageAmount,
    getPersonalLiabilityCoverageAmount,
    getReplacementCostCoverage,
    getRoofCoverage,
    getSeparateStructureCoverageAmount,
    getSinkholeCollapseCoverage,
    getWaterBackupCoverageAmount,
    getWindDeductibleAmount
} from 'common/source/utilities/policyInfoHelper';

export function getFullAddress(policyInfo: CustomerPolicyInfo) {
    return getStreetAddress(policyInfo) + ', ' + getCityStateAndZipcode(policyInfo);
}

export function getPolicyNumber(policyInfo: CustomerPolicyInfo) {
    return policyInfo.policy_number;
}

export function getPolicyEffectiveDateRange(policyInfo: CustomerPolicyInfo) {
    return `${policyInfo.effective_date} - ${policyInfo.expiration_date}`;
}

export function getCityStateAndZipcode(policyInfo: AnyPolicyInfo) {
    const city = capitalizeWords(policyInfo.property_data.address.city);
    const state = policyInfo.property_data.address.state.toUpperCase();
    const zipcode = policyInfo.property_data.address.zip;

    return `${city}, ${state}, ${zipcode}`;
}

export function getStreetAddress(policyInfo: AnyPolicyInfo) {
    const street = capitalizeWords(policyInfo.property_data.address.street);
    const street2 = capitalizeWords(policyInfo.property_data.address.street2);

    if (street2) {
        return street + ' ' + street2;
    } else {
        return street;
    }
}

export function getFormattedGeneralDeductibleAmount(policyInfo: AnyPolicyInfo) {
    const generalDeductibleAmount = getGeneralDeductibleAmount(policyInfo);
    return generalDeductibleAmount === null ? null : formatAsDollarsWithoutZeroCents(generalDeductibleAmount);
}

export function getFormattedWindDeductibleAmount(policyInfo: AnyPolicyInfo) {
    const windDeductibleAmount = getWindDeductibleAmount(policyInfo);
    return windDeductibleAmount === null ? null : formatAsDollarsWithoutZeroCents(windDeductibleAmount);
}

export function getFormattedHurricaneDeductibleAmount(policyInfo: AnyPolicyInfo) {
    const hurricaneDeductibleAmount = getHurricaneDeductibleAmount(policyInfo);
    return hurricaneDeductibleAmount === null ? null : formatAsDollarsWithoutZeroCents(hurricaneDeductibleAmount);
}

export function getFoundationAndWaterCoverageDisplay(policyInfo: AnyPolicyInfo) {
    const foundationAndWaterCoverageAmount = getFoundationAndWaterCoverageAmount(policyInfo);

    if (typeof foundationAndWaterCoverageAmount === 'number') {
        if (foundationAndWaterCoverageAmount === 0) {
            return 'None';
        } else {
            return formatAsDollarsWithoutZeroCents(foundationAndWaterCoverageAmount);
        }
    } else {
        return null;
    }
}

export function getFormattedBelongingsCoverageAmount(policyInfo: CustomerPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getBelongingsCoverageAmount(policyInfo));
}

export function getReplacementCostCoverageDisplay(policyInfo: CustomerPolicyInfo) {
    return getReplacementCostCoverage(policyInfo) ? 'Yes' : 'No';
}

export function getFormattedHomeCoverageAmount(policyInfo: AnyPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getHomeCoverageAmount(policyInfo));
}

export function getFormattedExtendedReplacementCostCoverageAmount(policyInfo: AnyPolicyInfo) {
    const extendedReplacementCostCoverageAmount = getExtendedReplacementCostCoverageAmount(policyInfo);

    if (typeof extendedReplacementCostCoverageAmount === 'number') {
        return formatAsDollarsWithoutZeroCents(extendedReplacementCostCoverageAmount);
    } else {
        return null;
    }
}

export function getFormattedSeparateStructureCoverageAmount(policyInfo: AnyPolicyInfo) {
    const separateStructureCoverageAmount = getSeparateStructureCoverageAmount(policyInfo);

    if (typeof separateStructureCoverageAmount === 'number') {
        return formatAsDollarsWithoutZeroCents(separateStructureCoverageAmount);
    } else {
        return null;
    }
}

export function getFormattedLossOfUseCoverageAmount(policyInfo: AnyPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getLossOfUseCoverageAmount(policyInfo));
}

export function getFormattedOrdinanceProtectionCoverageAmount(policyInfo: AnyPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getOrdinanceProtectionCoverageAmount(policyInfo));
}

export function getFormattedPersonalLiabilityCoverageAmount(policyInfo: CustomerPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getPersonalLiabilityCoverageAmount(policyInfo));
}

export function getFormattedMedicalPaymentCoverageAmount(policyInfo: CustomerPolicyInfo) {
    return formatAsDollarsWithoutZeroCents(getMedicalPaymentCoverageAmount(policyInfo));
}

export function getWaterBackupCoverageDisplay(policyInfo: CustomerPolicyInfo) {
    const waterBackupCoverage = getWaterBackupCoverageAmount(policyInfo);

    if (typeof waterBackupCoverage === 'number') {
        return waterBackupCoverage === 0 ? 'Waived' : formatAsDollarsWithoutZeroCents(waterBackupCoverage);
    } else {
        return null;
    }
}

export function getRoofCoverageDisplay(policyInfo: AnyPolicyInfo) {
    const roofCoverage = getRoofCoverage(policyInfo);

    if (typeof roofCoverage === 'boolean') {
        return roofCoverage ? 'ACV' : 'Repl. cost';
    } else {
        return null;
    }
}

export function getSinkholeCollapseCoverageDisplay(policyInfo: AnyPolicyInfo) {
    const sinkholeCollapseCoverage = getSinkholeCollapseCoverage(policyInfo);

    if (typeof sinkholeCollapseCoverage === 'number') {
        return sinkholeCollapseCoverage ? 'Included' : 'None';
    } else {
        return null;
    }
}

export function getMineSubsidenceCoverageDisplay(policyInfo: AnyPolicyInfo) {
    const mineSubsidenceCoverage = getMineSubsidenceCoverage(policyInfo);
    const mineSubsidenceAdjustment = policyInfo.adjustments.mine_subsidence;

    if (typeof mineSubsidenceCoverage === 'boolean' && mineSubsidenceAdjustment) {
        if (policyInfo.property_data.address.state.toUpperCase() === 'IL' && !mineSubsidenceCoverage) {
            return 'Rejected';
        }
        const mineSubsidenceOption = mineSubsidenceAdjustment.options[mineSubsidenceCoverage];
        if (typeof mineSubsidenceOption === 'string') {
            return mineSubsidenceOption;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function getFormattedPaymentMethodName(policyInfo: AnyPolicyInfo) {
    const paymentMethodName = policyInfo.checkout_data.payment_method;
    if (paymentMethodName === 'cc') {
        return 'Credit Card';
    } else {
        return 'Escrow';
    }
}

import * as angular from 'angular';
import * as _ from 'lodash';

const module = angular.module('invalid-street', []);

module.directive('invalidStreet', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(
            this: angular.IDirective,
            $scope: angular.IScope,
            $element: JQLite,
            attrs: angular.IAttributes,
            ctrl?: angular.IController
        ) {
            if (this.require === 'ngModel') {
                (ctrl as angular.INgModelController).$validators.invalidStreet = (modelValue: any, viewValue: any) => {
                    if (!_.isEmpty(viewValue)) {
                        const streetRegex = /[a-zA-Z0-9-'. ]+ [a-zA-Z0-9 -'.]+/;
                        return !!streetRegex.test(viewValue);
                    } else {
                        return false;
                    }
                };
            }
        }
    };
});

export { module };

import * as angular from 'angular';

import * as LoginState from 'states/login';
import * as SupportState from 'states/support';
import * as LoginByAddress from 'states/login-by-address';
import * as PolicyState from 'states/policy';
import * as ContactUsState from 'states/contact-us';
import * as LogoutState from 'common/source/states/logout';

export const states = {
    login: LoginState,
    loginByAddress: LoginByAddress,
    support: SupportState,
    logout: LogoutState,
    policy: PolicyState,
    contactUs: ContactUsState
};

export const module = angular.module('lenderPortalStates', []);

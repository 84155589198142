import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import 'angular-messages';

const module = angular.module('edit-section', ['ngMessages']);

interface EditSectionController extends angular.IStrictController {
    edit: () => void;
    save: () => void;
    cancel: () => void;
    isEditMode: boolean;
    onSave: () => void;
    onEdit: () => void;
    isSaveDisabled: boolean;
    isEditDisabled: boolean;
}

module.component('editSection', {
    template: template,
    transclude: {
        editOnlyContent: '?editOnlyContent',
        readOnlyContent: '?readOnlyContent'
    },
    bindings: {
        policyInfo: '<',
        onSave: '&',
        onEdit: '&',
        isSaveDisabled: '<',
        isEditDisabled: '<',
        testId: '@'
    },
    controller: function(this: EditSectionController, $timeout: angular.ITimeoutService, $element: JQLite) {
        'ngInject';

        this.$onInit = () => {};

        this.edit = () => {
            this.onEdit();
            this.isEditMode = true;

            $timeout(() => {
                const input = $element.find('input')[0];
                input.focus();
            });
        };

        this.save = () => {
            this.isEditMode = false;
            this.onSave();
        };

        this.cancel = () => {
            this.isEditMode = false;
        };

        this.$onChanges = (changesObj) => {
            const isSaveDisabled = changesObj.isSaveDisabled;
            const isEditDisabled = changesObj.isEditDisabled;

            if (isSaveDisabled && isSaveDisabled.currentValue !== undefined) {
                $timeout(() => {
                    this.isSaveDisabled = isSaveDisabled.currentValue;
                });
            }

            if (isEditDisabled && isEditDisabled.currentValue !== undefined) {
                $timeout(() => {
                    this.isEditDisabled = isEditDisabled.currentValue;
                });
            }
        };
    }
});

export { module };

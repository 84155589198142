import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

const module = angular.module('mortgage-escrow-check-box', []);

module.component('mortgageEscrowCheckBox', {
    template: template,
    controller: function() {
        'ngInject';
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

const module = angular.module('info-icon-with-tooltip', []);

interface InfoIconWithTooltipController extends angular.IStrictController {
    position?: string;
    color?: string;
    text: string;
}

module.component('infoIconWithTooltip', {
    template: template,
    transclude: true,
    bindings: {
        position: '@?',
        color: '@?',
        text: '<',
        tooltipSize: '@?',
        tooltipClass: '@?'
    },
    controller: function(this: InfoIconWithTooltipController, $element: angular.IAugmentedJQuery) {
        this.$onInit = () => {
            if (this.color) {
                $element.addClass(this.color);
            }
        };
    }
});

export { module };

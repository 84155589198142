import * as angular from 'angular';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('contact-us-state', []);

const onEnter = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).addClass('unauthenticated-layout');
};

const onExit = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).removeClass('unauthenticated-layout');
};

const template = '<contact-us-page></contact-us-page>';

const controller = function(

    $scope: angular.IScope,
    authenticationService: AuthenticationService
) {
    'ngInject';

    // Make sure that correct header is shown
    if (authenticationService.isLoggedIn()) {
        authenticationService.logout(false);
    }

};

export { module, template, controller, onEnter, onExit };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { ModalService } from 'common/source/services/modal';

const module = angular.module('need-customer-approval', []);

interface NeedCustomerApprovalController extends angular.IStrictController {
    confirm: () => void;
}

module.component('needCustomerApproval', {
    template: template,
    controller: function(this: NeedCustomerApprovalController, modalService: ModalService) {
        this.confirm = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as angular from 'angular';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as template from './template.html';
import './styling.scss';
import * as PolicyPageComponents from './components';
import { ModalService } from 'common/source/services/modal';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';
import { getMomentFromHippoDateString } from 'common/source/utilities/dates';

const module = angular.module('policy-page', [PolicyPageComponents.module.name]);

interface PolicyPageController extends angular.IStrictController {
    policyInfo: LenderPolicyInfo;
    hasPendingTransaction: boolean;
    needCustomerApproval: boolean;
    isCancelled: boolean;
}

module.component('policyPage', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: PolicyPageController,
        modalService: ModalService,
        $rootScope: angular.IRootScopeService,
        lenderPolicyInfoService: LenderPolicyInfoService
    ) {
        this.$onInit = () => {
            const isEffectiveDateTodayOrInThePast = getMomentFromHippoDateString(this.policyInfo.effective_date).diff(moment()) < 0;
            this.isCancelled = isPolicyCancelled(this.policyInfo);
            if (this.policyInfo.transaction && _.isPlainObject(this.policyInfo.transaction)) {
                this.hasPendingTransaction = true;
                if (this.policyInfo.transaction.require_contact_info) {
                    modalService.openUncloseableModal('confirm-eoi-updates', { showContactForm: true });
                } else {
                    modalService.openUncloseableModal('changes-under-review');
                }
            } else if (
                this.policyInfo.need_customer_approval && isEffectiveDateTodayOrInThePast) {
                this.needCustomerApproval = true;
                if (!this.isCancelled) {
                    modalService.openUncloseableModal('need-customer-approval');
                }
            }
        };

        const updatePolicy = () => {
            this.policyInfo = lenderPolicyInfoService.getPolicyInfo() as LenderPolicyInfo;
            this.hasPendingTransaction = _.isPlainObject(this.policyInfo.transaction);
        };

        const deregisterLenderPolicyInfoUpdatedWithoutSaving = $rootScope.$on(
            'lenderPolicyInfoUpdatedWithoutSaving',
            updatePolicy
        );

        const deregisterLenderPolicyInfoChangesApplied = $rootScope.$on('lenderPolicyInfoChangesApplied', updatePolicy);

        this.$onDestroy = () => {
            deregisterLenderPolicyInfoUpdatedWithoutSaving();
            deregisterLenderPolicyInfoChangesApplied();
        };
    }
});

export { module };

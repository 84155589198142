import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';

const module = angular.module('edit-second-insured', []);

interface SecondInsuredFormScope extends angular.IScope {
    secondInsuredForm: angular.IFormController;
}
interface EditSecondInsuredController extends angular.IStrictController {
    firstName: string;
    middleName?: string;
    lastName: string;
    dateOfBirth: string & NominalType<'AmericanDateString'>;
    save: () => void;
    cancel: () => void;
    secondInsuredForm: angular.IFormController;
}

module.component('editSecondInsured', {
    template: template,
    controller: function(
        this: EditSecondInsuredController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        modalService: ModalService,
        $element: angular.IAugmentedJQuery,
        $timeout: angular.ITimeoutService
    ) {
        let policyInfo: LenderPolicyInfo | undefined;

        this.$onInit = () => {
            policyInfo = lenderPolicyInfoService.getPolicyInfo();

            if (policyInfo && policyInfo.personal_information.second_insured) {
                this.dateOfBirth = policyInfo.personal_information.second_insured.date_of_birth;
                this.lastName = policyInfo.personal_information.second_insured.last_name;
                this.firstName = policyInfo.personal_information.second_insured.first_name;
                this.middleName = policyInfo.personal_information.second_insured.middle_name;
            }

            $timeout(() => {
                const form = $element.find('form').scope() as SecondInsuredFormScope;
                this.secondInsuredForm = form.secondInsuredForm;
            });
        };

        this.save = () => {
            this.secondInsuredForm.$setSubmitted();
            if (policyInfo && this.secondInsuredForm.$valid) {
                policyInfo.personal_information.second_insured = {
                    first_name: this.firstName,
                    middle_name: this.middleName,
                    last_name: this.lastName,
                    date_of_birth: this.dateOfBirth
                };

                lenderPolicyInfoService.setPolicyInfo(policyInfo);

                modalService.closeAllDialogs();
            }
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as angular from 'angular';

import * as AnalyticsService from 'common/source/services/analytics';
import * as HippoApiService from './hippoApi';
import * as NavigationService from './navigation';
import * as AuthenticationService from './authentication';
import * as LenderPolicyInfoService from './lenderPolicyInfo';
import * as MortgageService from './mortgage';
import * as ModalService from 'common/source/services/modal';

export const module = angular.module('lender-portal-services', [
    HippoApiService.module.name,
    NavigationService.module.name,
    AuthenticationService.module.name,
    LenderPolicyInfoService.module.name,
    ModalService.module.name,
    AnalyticsService.module.name,
    MortgageService.module.name
]);

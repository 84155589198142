import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { ModalService } from 'common/source/services/modal';

const module = angular.module('changes-under-review', []);

interface ChangesUnderReviewController extends angular.IStrictController {
    confirm: () => void;
    changesUnderReviewCopy: string;
}

module.component('changesUnderReview', {
    template: template,
    controller: function(this: ChangesUnderReviewController, modalService: ModalService) {
        this.confirm = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

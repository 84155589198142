import * as angular from 'angular';
import * as Panel from 'common/source/components/panel';
import * as template from './template.html';
import './styling.scss';

const module = angular.module('contact-us-page', [Panel.module.name]);

module.component('contactUsPage', {
    template: template
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';
import 'angular-messages';

const module = angular.module('effective-date', ['ngMessages']);

interface EffectiveDateController extends angular.IStrictController {
    title: string;
    icon: string;
    policyInfo: LenderPolicyInfo;
    effectiveDate: AmericanDateString;
    isEditMode: boolean;
    onSave: () => void;
    onEdit: () => void;
    saveCursor: (event: KeyboardEvent) => void;
    restoreCursor: (event: KeyboardEvent) => void;
    autocompleteYear: () => void;
    cursorPosition: number;
    isEditDisabled: boolean;
}

module.component('effectiveDate', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: EffectiveDateController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        $element: angular.IAugmentedJQuery
    ) {
        'ngInject';

        this.$onInit = () => {
            this.icon = 'calendar';
            this.title = 'Policy Effective Date';
            this.effectiveDate = this.policyInfo.effective_date;
            this.isEditDisabled = !this.policyInfo.allow_effective_date_change || isPolicyCancelled(this.policyInfo);
        };

        this.autocompleteYear = () => {
            if (this.effectiveDate.length === 8) {
                const replaceRegex = /([\d]{2}\/[\d]{2}\/)([\d]{2})/;
                this.effectiveDate = this.effectiveDate.replace(replaceRegex, '$120$2') as AmericanDateString;
            }
        };

        this.onSave = () => {
            this.policyInfo.effective_date = this.effectiveDate;
            lenderPolicyInfoService.setPolicyInfo(this.policyInfo);
        };

        this.onEdit = () => {
            this.effectiveDate = this.policyInfo.effective_date;
        };
    }
});

export { module };

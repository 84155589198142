import * as angular from 'angular';
import * as LogoutPage from 'common/source/pages/logout';

const module = angular.module('logout-state', [LogoutPage.module.name]);

const resolve = {};

const onEnter = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).addClass('unauthenticated-layout');
};

const onExit = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).removeClass('unauthenticated-layout');
};

const template = '<logout-page></logout-page>';

interface LogoutPageContainerScope extends angular.IStrictController {}

const controller = function($scope: LogoutPageContainerScope) {
    'ngInject';
};

export { module, template, controller, resolve, onEnter, onExit };

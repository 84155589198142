import * as angular from 'angular';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AMERICAN_DATE_FORMAT, AMERICAN_DATE_FORMAT_SHORT_YEAR } from 'common/source/utilities/dates';

const module = angular.module('invalid-date-of-birth', []);

module.directive('invalidDateOfBirth', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(
            this: angular.IDirective,
            $scope: angular.IScope,
            $element: JQLite,
            attrs: angular.IAttributes,
            ctrl?: angular.IController
        ) {
            if (this.require === 'ngModel') {
                (ctrl as angular.INgModelController).$validators.invalidDateOfBirth = (
                    modelValue: any,
                    viewValue: any
                ) => {
                    const longFormat = moment(viewValue, AMERICAN_DATE_FORMAT, true);
                    const shortFormat = moment(viewValue, AMERICAN_DATE_FORMAT_SHORT_YEAR, true);
                    const momentDate = longFormat.isValid() ? longFormat : shortFormat;
                    if (!_.isEmpty(viewValue) && momentDate.isValid()) {
                        return momentDate.isSameOrAfter(moment('01/01/1900', 'DD/MM/YYYY'));
                    } else {
                        return false;
                    }
                };
            }
        }
    };
});

export { module };

import * as moment from 'moment';
import * as angular from 'angular';

export const INTERNATIONAL_DATE_FORMAT = 'YYYY-MM-DD';
export const AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';
export const AMERICAN_DATE_FORMAT_SHORT_YEAR = 'MM/DD/YY';
export const TWELVE_HOUR_WITH_MERIDIEM_TIME_FORMAT = 'hh:mm A';
export const AMERICAN_DATE_FORMAT_ORDINAL_MEDIUM = 'MMM Do, YYYY';

export type HippoDateString = AmericanDateString | InternationalDateString | Iso8601String;

// For future nominal return types, if considered worthwhile:
//
// enum _ShortDescriptiveDateString {}
// export type ShortDescriptiveDateString = string & _ShortDescriptiveDateString;
//
// enum _ShortAmericanDateString {}
// export type ShortAmericanDateString = string & _ShortAmericanDateString;
//
// enum _YearString {}
// export type YearString = string & _YearString;

export function getMomentFromHippoDateString(dateString: HippoDateString): moment.Moment {
    return moment(dateString, [moment.ISO_8601, AMERICAN_DATE_FORMAT, INTERNATIONAL_DATE_FORMAT]);
}

export function getLongNumericAmericanDateString(dateString: HippoDateString): string {
    return getMomentFromHippoDateString(dateString).format(AMERICAN_DATE_FORMAT);
}

export function getShortNumericAmericanDateString(dateString: HippoDateString): string {
    return getMomentFromHippoDateString(dateString).format('MM/DD/YY');
}

export function getShortDescriptiveDateString(dateString: HippoDateString): string {
    return getMomentFromHippoDateString(dateString).format('DD MMM YYYY');
}

export function getYearFromHippoDateString(dateString: HippoDateString): string {
    return getMomentFromHippoDateString(dateString).format('YYYY');
}

export function isTodayWithinFiveDaysBeforeDate(dateString: HippoDateString): boolean {
    return moment().isBetween(
        moment(dateString).subtract(5, 'days'),
        moment(dateString),
        'day',
        // end date is included as true
        '(]'
    );
}

export function isTodayBetween20And30DaysAfterDate(dateString: HippoDateString): boolean {
    return moment().isBetween(
        moment(dateString).add(20, 'days'),
        moment(dateString).add(30, 'days'),
        'day',
        // end date is included as true
        '(]'
    );
}

const module = angular.module('customerPortal.utilities.dates', []);

module.value('getMomentFromHippoDateString', getMomentFromHippoDateString);

import * as angular from 'angular';
import * as template from './template.html';
import * as hippoConstFactory from 'common/hippoConst.js';
import './styling.scss';

const module = angular.module('billing-addresses', []);

interface PaymentAddress {
    name: string;
    line1: string;
    city: string;
    state: string;
    zip: string;
}

interface OvernightPaymentAddress {
    name: string;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
}

interface BillingAddressesController extends angular.IStrictController {
    title: string;
    icon: string;
    paymentAddress: PaymentAddress;
    overnightPaymentAddress: OvernightPaymentAddress;
    policyInfo: LenderPolicyInfo;
}

module.component('billingAddresses', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(this: BillingAddressesController) {
        'ngInject';

        const HIPPO_CONSTS = hippoConstFactory();
        const paymentAddresses: { [index: string]: PaymentAddress } = HIPPO_CONSTS.PAYMENT_ADDRESSES_BY_CARRIER;
        const overnightPaymentAddresses: { [index: string]: OvernightPaymentAddress } =
            HIPPO_CONSTS.OVERNIGHT_PAYMENT_ADDRESSES_BY_CARRIER;

        this.$onInit = () => {
            this.icon = 'documents';
            this.title = 'Billing';
            this.paymentAddress = paymentAddresses[this.policyInfo.carrier || ''];
            this.overnightPaymentAddress = overnightPaymentAddresses[this.policyInfo.carrier || ''];
        };
    }
});

export { module };

/**
 * @description Automatic date input formatting in input field
 * Like ui-mask BUT slightly different.. for pattern MM/DD/YYYY, we will auto-add separator ('/' for example) BEFORE
 * the next keystroke.
 *
 * For example - for date 01/09/1981 and keystrokes (01091981)
 * Our Directive - 0 , 01/ , 01/0, 01/09/, 01/09/1, ...
 * ui-mask - 0, 01, 01/0, 01/09, 01/09/1, ...
 *
 * See how '/' is added one step before!
 *
 *
 * improvement suggestions:
 *
 * 1. To have the template here instead of fields.html. This will allow us to properly bind to events like ng-paste
 * 2. To align to angular ngModelCtrl $render, $parsers, $validators, $formatter better.
 *    But - it is not as easy as it looks, so we can't just jump into it.
 *    I will feel more comfortable with this if we had covered the entire component with tests first.
 *    Here is a good reference: https://www.linkedin.com/pulse/all-angularjs-formatters-parsers-anuradha-bandara
 *
 *    One complication, for example - formatters are not triggered by a change to modelValue. only by direct change on the scope.
 *
 **/
import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';

const module = angular.module('date-input', []);

interface DateInputScope extends angular.IScope {
    processChange: any; // replace any with actual type
}

module.directive('dateInput', [
    '$timeout',
    function($timeout) {
        return {
            require: '^ngModel',
            restrict: 'A',
            link: function(
                this: angular.IDirective,
                $scope: DateInputScope,
                $element: JQLite,
                $attrs: angular.IAttributes,
                ctrl?: angular.IController
            ) {
                const ngModelCtrl: angular.INgModelController = <angular.INgModelController>ctrl;
                const momentFormat = $attrs.format || 'MM/DD/YYYY';
                const includeDays = momentFormat.indexOf('DD') > -1;
                const totalLength = momentFormat.length;
                const separator = momentFormat.charAt(2);
                const inputEl: HTMLInputElement = <HTMLInputElement>$element[0];
                let parseTwoDigitYear = moment.parseTwoDigitYear;

                function isNumber(char: string) {
                    return /[0-9]/.test(char);
                }

                function isSeparator(char: string) {
                    return char === separator;
                }

                function canAutoHandleInput(input: string) {
                    return input.length === 2 || (input.length === 5 && includeDays);
                }

                /**
                 * handles following scenarios:
                 *  last two characters are a number and separator ==> pads number with zero
                 *  last two characters are numbers ==> adds separator
                 * @param {Array<string>} args array of characters including separator for example [ '1' , '2' , '/' , '3' , '/' ]
                 */
                function autoHandleInput(args: Array<string>) {
                    if (isNumber(args[args.length - 2]) && isSeparator(args[args.length - 1])) {
                        // number and separator
                        args.splice(args.length - 2, 0, '0');
                    } else if (isNumber(args[args.length - 2]) && isNumber(args[args.length - 1])) {
                        // 2 numbers
                        args.push(separator);
                    }
                    return args;
                }

                /**
                 * This function does the following
                 *
                 *  - Adds separator if next char should be separator
                 *  - Pads number with 0 on left if user neglected
                 *
                 *
                 *  NOTE: WE CANNOT ASSUME RECENT KEYPRESS EVENT WAS AT END OF STRING! ==> Hence this function ignores the keycode
                 *  Plus - in mobile keyboard, events keycodes may differ
                 *
                 * @param dt
                 * @returns {*}
                 */
                function processChange(dt: any, atEnd: any) {
                    if (!dt) {
                        return '';
                    }

                    dt = dt.replace(new RegExp(`[^\\${separator}0-9]`, 'gi'), ''); // remove illegal characters.. http://stackoverflow.com/a/21415201
                    if (atEnd && canAutoHandleInput(dt)) {
                        dt = autoHandleInput(dt.split('')).join('');
                    }

                    // if string turned out bigger than shoul dbe, truncate
                    if (dt.length > totalLength) {
                        dt = dt.slice(0, totalLength);
                    }
                    return dt;
                }

                function validateDate(dt: any, min: any, max: any) {
                    const momentobj = moment(dt, momentFormat);

                    if (momentobj.isValid() && dt.length === totalLength) {
                        min = moment(min, momentFormat).valueOf();
                        max = moment(max, momentFormat).valueOf();
                        const time = momentobj.valueOf();
                        if (time > max) {
                            return 'date max';
                        } else if (time < min) {
                            return 'date min';
                        } else {
                            return momentobj.format(momentFormat);
                        }
                    } else {
                        return 'invalid date';
                    }
                }

                const applyFormat = function(value: any) {
                    const isDateOfBirth = $attrs.isDateOfBirth === 'true';
                    const currentTwoDigitYear = parseInt(moment().format('YY'));
                    const oldParser = parseTwoDigitYear;
                    if (isDateOfBirth) {
                        parseTwoDigitYear = (twoDigitYearString) => {
                            const twoDigitYearInt = parseInt(twoDigitYearString);
                            return twoDigitYearInt + (twoDigitYearInt > currentTwoDigitYear ? 1900 : 2000);
                        };
                    }

                    const momentObj = moment(value, momentFormat);
                    if (momentObj.isValid()) {
                        let newValue = momentObj.format(momentFormat);
                        $element.val(newValue);
                        ngModelCtrl.$setViewValue(newValue);
                        validateElement(newValue);

                        // Do nothing if date entered is not today
                        if (moment().isSame(value, 'day')) {
                            // If input is focused
                            if ($element[0] === document.activeElement) {
                                newValue.replace(' (today)', '');
                                $element.val(newValue);
                            } else {
                                newValue += ' (today)';
                                $element.val(newValue);
                            }
                        }
                    }
                    parseTwoDigitYear = oldParser;
                };

                const handleChange = function(value: any) {
                    if (value) {
                        const cursorPosition = inputEl.selectionStart;
                        const atEnd = cursorPosition === value.length;
                        const newVal = processChange(value, atEnd);
                        if (value !== newVal) {
                            // our processing's implementation currently is only valid if cursor is at the end
                            ngModelCtrl.$setViewValue(newVal);
                            ngModelCtrl.$render();
                            $element.val(newVal); // guy: strange - don't know why ngModel.$setViewValue && $render is not enough here

                            if (!atEnd) {
                                // restore cursor position
                                inputEl.selectionStart = cursorPosition;
                                inputEl.selectionEnd = cursorPosition;
                            } else {
                                // some devices need this. I saw this on android devices
                                // alert('setting at end' + newVal.length);
                                $timeout(function() {
                                    inputEl.selectionStart = newVal.length;
                                    inputEl.selectionEnd = newVal.length;
                                });
                            }
                        }
                    }
                };

                function setValidity(type: any, value: any) {
                    ngModelCtrl.$setValidity(type, value);
                    return value;
                }

                const validateElement = function(dateString: any) {
                    if (dateString) {
                        const validationResult = validateDate(dateString, $attrs.min, $attrs.max);
                        const elementValid =
                            setValidity('date_invalid', validationResult !== 'invalid date') &&
                            setValidity('date_max', validationResult !== 'date max') &&
                            setValidity('date_min', validationResult !== 'date min');
                        inputEl.setCustomValidity(elementValid ? '' : validationResult); // support our "tryNext" function which uses native js
                    }
                };

                // This runs when we update the text field
                let oldValue = ''; // seems that in this method we need to update old value
                ngModelCtrl.$viewChangeListeners.push(() => {
                    const newValue = ngModelCtrl.$modelValue;
                    if (oldValue && newValue && newValue.length < oldValue.length) {
                        oldValue = newValue;
                        validateElement($element.val());
                        return;
                    }
                    if (newValue && newValue.length > oldValue.length + 1) {
                        // paste
                        applyFormat(newValue);
                    }

                    handleChange($element.val());
                    oldValue = $element.val();
                    validateElement($element.val());
                });

                $element.bind('blur', function() {
                    applyFormat($element.val());
                });

                $element.bind('focus', function() {
                    const selectionStart = inputEl.selectionStart;
                    const selectionEnd = inputEl.selectionEnd;

                    applyFormat($element.val());

                    inputEl.selectionStart = selectionStart;
                    inputEl.selectionEnd = selectionEnd;
                });

                // apparently this is required. inspired by: http://stackoverflow.com/a/14693201 - with comments from Ed on PR
                // this solves issue where value already exists on load, and user starts to delete the date
                ngModelCtrl.$render = function() {
                    oldValue = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : '';

                    // Only apply formatting if we are rendering today's date
                    if (moment().isSame(ngModelCtrl.$viewValue, 'day')) {
                        applyFormat(ngModelCtrl.$viewValue);
                    } else {
                        validateElement(ngModelCtrl.$viewValue);
                        $element.val(ngModelCtrl.$viewValue);
                    }
                };

                // FOR TESTING! DON'T REMOVE!
                $scope.processChange = processChange;
            }
        };
    }
]);

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';

import './styling.scss';
import * as template from './template.html';

import { HippoApiService, HippoConfigurationLicenses } from 'services/hippoApi';

const module = angular.module('app-footer-component', []);

interface FooterLicense {
    state: string;
    licenses: string;
}

type FooterLicenses = Array<FooterLicense>;

interface IntermediateFooterLicenses {
    [state: string]: Array<string>;
}

interface AppFooterController extends angular.IStrictController {
    claimsSref: string;
    licenses: FooterLicenses;
    isSimpleLayout: boolean;
    layout: string;
    currentYear: number;
}

module.component('appFooter', {
    template: template,
    bindings: {
        claimsSref: '<',
        layout: '@'
    },
    controller: function(this: AppFooterController, hippoApiService: HippoApiService) {
        this.$onInit = function() {
            this.isSimpleLayout = this.layout === 'simple';
            this.currentYear = moment().year();
            hippoApiService.getStateLicenses().then((configurationLicenses: HippoConfigurationLicenses) => {
                const stateLicenseArrays: IntermediateFooterLicenses = {};

                _.each(configurationLicenses, (value, key) => {
                    const state = key.substr(0, 2).toUpperCase();

                    if (stateLicenseArrays[state]) {
                        stateLicenseArrays[state].push(value.substr(3));
                    } else {
                        stateLicenseArrays[state] = [value.substr(3)];
                    }
                });

                this.licenses = _.map(stateLicenseArrays, (licenses, state) => {
                    if (licenses.length > 1) {
                        return {
                            state: state,
                            licenses: '#' + licenses.join(', #')
                        };
                    } else {
                        return {
                            state: state,
                            licenses: '#' + licenses[0]
                        };
                    }
                });

                this.licenses.sort((first: FooterLicense, second: FooterLicense) => {
                    return first.state < second.state ? -1 : 1;
                });
            });
        };
    }
});

export { module };

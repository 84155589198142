import * as angular from 'angular';

const module = angular.module('hippo-availability', []);

interface HippoAvailabilityController extends angular.IStrictController {
    class: string;
}

module.component('hippoAvailability', {
    bindings: {
        class: '@'
    },
    template: `
      <div class="availability {{$ctrl.class}}">We are here Monday - Friday, 7 a.m. to 8 p.m. CT.</div>
    `,
    controller: function(this: HippoAvailabilityController) {}
});

export { module };

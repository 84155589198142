import * as angular from 'angular';
import { AuthenticationService } from 'services/authentication';
import { NavigationService } from 'services/navigation';

const module = angular.module('login-state', []);

const resolve = {};

const onEnter = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).addClass('unauthenticated-layout');
};

const onExit = function() {
    const body = document.querySelector('body') as HTMLElement;
    angular.element(body).removeClass('unauthenticated-layout');
};

const template = '<login-page></login-page>';

interface LoginPageContainerScope extends angular.IStrictController {}

const controller = function(
    $scope: LoginPageContainerScope,
    authenticationService: AuthenticationService,
    navigationService: NavigationService
) {
    'ngInject';

    // If they're already logged in let's send them to the policy page
    if (authenticationService.isLoggedIn()) {
        navigationService.toPolicyPage();
    }
};

export { module, template, controller, resolve, onEnter, onExit };

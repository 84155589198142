import * as angular from 'angular';
import { StateService } from '@uirouter/angularjs';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { LoginByAddressParameters } from 'services/authentication';
import * as _ from 'lodash';

export const module = angular.module('hippoApiService', []);

// TODO: Move to common/source/types
export interface HippoConfigurationLicenses {
    [STATE_CARRIER: string]: string;
}

interface HippoConfiguration {
    licenses: HippoConfigurationLicenses;
    supported_states: Array<string>;
}

interface LoginInfo {
    policyNumber: string;
    zipcode: string;
    lastName: string;
}

interface SearchParameters {
    password: string;
    last_name: string;
    street?: string;
    city?: string;
    type: 'city' | 'street';
}

interface MatchingStreetsAndCities {
    cities: Array<string>;
    streets: Array<string>;
}

export class HippoApiService {
    private $http: angular.IHttpService;
    private $q: angular.IQService;
    private $state: StateService;
    private $window: angular.IWindowService;
    private lenderPolicyInfoService: LenderPolicyInfoService;

    constructor(
        $http: angular.IHttpService,
        $state: StateService,
        $q: angular.IQService,
        $window: angular.IWindowService,
        lenderPolicyInfoService: LenderPolicyInfoService
    ) {
        this.$http = $http;
        this.$state = $state;
        this.$q = $q;
        this.$window = $window;
        this.lenderPolicyInfoService = lenderPolicyInfoService;
    }

    private response401Interceptor<T>(response: angular.IHttpResponse<T>, alwaysReject: boolean = false) {
        if (response.status === 401) {
            return this.$q.reject(response);
        }

        if (alwaysReject) {
            return this.$q.reject(response);
        } else {
            return response;
        }
    }

    private success401Interceptor<T>(response: angular.IHttpResponse<T>) {
        return this.response401Interceptor(response);
    }

    private error401Interceptor<T>(response: angular.IHttpResponse<T>) {
        return this.response401Interceptor(response, true);
    }

    getApiBaseUrl() {
        const apiVersion = '/v1/';

        if (this.$window.appConfig && this.$window.appConfig.API_HOST) {
            return this.$window.appConfig.API_HOST + apiVersion;
        }

        let port = '';
        let hostPrefix = '';

        if (this.$window.host === 'myhippo.com') {
            hostPrefix = 'api.';
        } else if (this.$window.location.port) {
            port = ':3000';
        } else {
            hostPrefix = 'api-';
        }

        const location = this.$window.location;

        const protocol = location.protocol;
        const hostname = location.hostname;

        return `${protocol}//${hostPrefix}${hostname}${port}${apiVersion}`;
    }

    get<T>(path: string, intercept401s: boolean = true): angular.IPromise<angular.IHttpResponse<T>> {
        const request = this.$http<T>({
            method: 'GET',
            url: this.getApiBaseUrl() + path
        });

        if (intercept401s) {
            return request
                .then((response) => {
                    return this.success401Interceptor(response);
                })
                .catch((response) => {
                    return this.error401Interceptor(response);
                });
        } else {
            return request;
        }
    }

    post<T>(path: string, data?: any): angular.IPromise<angular.IHttpResponse<T>> {
        const request = this.$http<T>({
            method: 'POST',
            url: this.getApiBaseUrl() + path,
            data: data
        });

        return request
            .then((response) => {
                return this.success401Interceptor(response);
            })
            .catch((response) => {
                return this.error401Interceptor(response);
            });
    }

    getConfiguration() {
        return this.get<HippoConfiguration>('configuration');
    }

    getKnownLenders() {
        return this.get<Array<KnownLenderInfo>>('agent/workflow/lenders').then(function(
            response: angular.IHttpResponse<Array<KnownLenderInfo>>
        ) {
            return response.data;
        });
    }

    getStateLicenses() {
        return (this.getConfiguration().then(function(response: angular.IHttpResponse<HippoConfiguration>) {
            return response.data.licenses;
        }) as angular.IPromise<any>) as angular.IPromise<HippoConfigurationLicenses>;
    }

    authenticateLender(policyNumber: string, zip: string, lastName: string) {
        const self = this;

        return this.post<LenderPolicyInfo>('lender/policy', {
            policy_number: policyNumber,
            zip,
            last_name: lastName
        }).then(function(response: angular.IHttpResponse<LenderPolicyInfo>) {
            const lenderPolicyInfo = response.data;
            self.lenderPolicyInfoService.setPolicyInfo(lenderPolicyInfo);
            return lenderPolicyInfo;
        });
    }

    authenticateByAddress(loginByAddressParameters: LoginByAddressParameters) {
        const self = this;

        loginByAddressParameters = _.merge(loginByAddressParameters, {
            user: 'lender-support'
        });

        return this.post<LenderPolicyInfo>('lender/address-login', loginByAddressParameters).then(function(
            response: angular.IHttpResponse<LenderPolicyInfo>
        ) {
            const lenderPolicyInfo = response.data;
            self.lenderPolicyInfoService.setPolicyInfo(lenderPolicyInfo);
            return lenderPolicyInfo;
        });
    }

    authenticateSupport(policyNumber: string, password: string) {
        const self = this;

        return this.post<LenderPolicyInfo>('lender/support-login', {
            policy_number: policyNumber,
            password: password,
            user: 'lender-support'
        }).then(function(response: angular.IHttpResponse<LenderPolicyInfo>) {
            const lenderPolicyInfo = response.data;
            self.lenderPolicyInfoService.setPolicyInfo(lenderPolicyInfo);
            return lenderPolicyInfo;
        });
    }

    searchMatchingCityStreet(searchParameters: SearchParameters) {
        searchParameters = _.merge(searchParameters, { user: 'lender-support' });
        return this.post<MatchingStreetsAndCities>('lender/support/search', searchParameters);
    }

    getPolicyInfo(policyNumber: string, zip: string, lastName: string) {
        const self = this;

        return this.post<LenderPolicyInfo>('lender/policy', {
            policy_number: policyNumber,
            zip,
            last_name: lastName
        }).then(function(response: angular.IHttpResponse<LenderPolicyInfo>) {
            const lenderPolicyInfo = response.data;
            self.lenderPolicyInfoService.setPolicyInfo(lenderPolicyInfo);
            return lenderPolicyInfo;
        });
    }

    updatePolicyInfo(lenderPolicyInfo: LenderPolicyInfoUpdate) {
        return this.post<LenderPolicyInfo>('lender/policy/update', lenderPolicyInfo);
    }

    updateLastEditor(loginInfo: LoginInfo, name: string, email: string) {
        const { policyNumber, zipcode, lastName } = loginInfo;

        return this.post<LenderPolicyInfo>('lender/policy/note', {
            lender_name: name,
            lender_email: email,
            zip: zipcode,
            last_name: lastName,
            policy_number: policyNumber
        });
    }

    // internalTracking(eventData: InternalTrackEvent) {
    //     return this.post<object>('track', eventData);
    // }

    sendFax(loginInfo: LoginInfo, faxData: FaxData) {
        const { policyNumber, zipcode, lastName } = loginInfo;
        return this.post<object>(
            'lender/policy/fax',
            _.merge(faxData, {
                zip: zipcode,
                last_name: lastName,
                policy_number: policyNumber
            })
        );
    }
}

module.factory('hippoApiService', function(
    $http: angular.IHttpService,
    $state: StateService,
    $q: angular.IQService,
    $window: angular.IWindowService,
    lenderPolicyInfoService: LenderPolicyInfoService
) {
    'ngInject';

    return new HippoApiService($http, $state, $q, $window, lenderPolicyInfoService);
});

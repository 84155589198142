import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import * as _ from 'lodash';
import { IAugmentedJQuery, ITimeoutService } from 'angular';

const module = angular.module('hippo-check-box', []);

interface HippoCheckBoxController extends angular.IStrictController {
    id: string;
    label: string;
    name: string;
}
module.component('hippoCheckBox', {
    bindings: {
        ngModel: '=',
        label: '@'
    },
    template: template,
    controller: function(this: HippoCheckBoxController, $element: IAugmentedJQuery, $timeout: ITimeoutService) {
        'ngInject';

        this.$onInit = () => {
            this.id = _.kebabCase(this.label);
            this.name = this.name || _.camelCase(this.label);
        };
    }
});

export { module };

import * as angular from 'angular';
import * as DisplayPolicyInfoComponent from './display-policy-info';
import * as EffectiveDateComponent from './effective-date';
import * as MortgageeClauseComponent from './mortgagee-clause';
import * as PaymentMethodComponent from './payment-method';
import * as PropertyAddressComponent from './property-address';
import * as VestingNameComponent from './vesting-name';
import * as CoverageComponent from './policy-coverage';
import * as EditSectionComponent from './edit-section';
import * as SupportInfoComponent from './support-info';
import * as EOIUpdatesComponent from './eoi-updates';
import * as BillingAddressesComponent from './billing-addresses';

const module = angular.module('policy-page-components', [
    DisplayPolicyInfoComponent.module.name,
    EffectiveDateComponent.module.name,
    MortgageeClauseComponent.module.name,
    PaymentMethodComponent.module.name,
    PropertyAddressComponent.module.name,
    VestingNameComponent.module.name,
    CoverageComponent.module.name,
    EditSectionComponent.module.name,
    SupportInfoComponent.module.name,
    EOIUpdatesComponent.module.name,
    BillingAddressesComponent.module.name
]);

export { module };

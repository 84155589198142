import { IDialogProvider } from 'ng-dialog';

export default function NgDialogConfig(ngDialogProvider: IDialogProvider) {
    'ngInject';

    ngDialogProvider.setDefaults({
        className: 'ngdialog-theme-default',
        plain: true,
        showClose: true,
        closeByDocument: true,
        closeByEscape: true,
        trapFocus: false,
        width: 740
    });
}

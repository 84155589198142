import * as angular from 'angular';
import { Transition, TransitionService } from '@uirouter/angularjs';
import 'angular-cookies';
import 'angucomplete-alt';
import '@uirouter/angularjs';
import 'angular-sanitize';
import 'angular-spinner';
import 'styling/index.scss';
import 'angular-tooltips';
import 'common/source/config/angular-tooltips';
import 'ng-dialog';

import UIRouterConfig from 'config/router';
import NgDialogConfig from 'common/source/config/ng-dialog';
import QProviderConfig from 'common/source/config/q-provider';
import UsSpinnerConfig from 'common/source/config/us-spinner';
import { UsSpinnerService } from 'common/source/types/angular-spinner';
import { AnalyticsService } from 'common/source/services/analytics';

import * as lenderPortalLayouts from 'layouts';
import * as lenderPortalStates from 'states';
import * as lenderPortalComponents from 'components';
import * as lenderPortalServices from 'services';
import * as lenderPortalPages from 'pages';
import * as lenderPortalDirectives from 'directives';
import * as lenderPortalModals from 'modals';

const app = angular.module('lenderPortal', [
    'ui.router',
    'ngSanitize',
    'ngCookies',
    'angularSpinner',
    '720kb.tooltips',
    'ngDialog',
    'angucomplete-alt',
    lenderPortalComponents.module.name,
    lenderPortalDirectives.module.name,
    lenderPortalStates.module.name,
    lenderPortalLayouts.module.name,
    lenderPortalServices.module.name,
    lenderPortalPages.module.name,
    lenderPortalModals.module.name
]);

app.config(UIRouterConfig);
app.config(NgDialogConfig);
app.config(QProviderConfig);
app.config(UsSpinnerConfig);

const contentLoadingSpinnerKey = 'content-loading-spinner';

app.controller('lenderPortalController', function(
    $rootScope: angular.IRootScopeService,
    $transitions: TransitionService,
    usSpinnerService: UsSpinnerService,
    analyticsService: AnalyticsService
) {
    'ngInject';

    $transitions.onStart({}, (transition: Transition) => {
        window.scrollTo(0, 0);

        if (transition.to().resolve) {
            try {
                const uiView = document.querySelector('ui-view') as HTMLElement;
                uiView.innerHTML = ''; // clear the view during the transition
            } catch (e) {
                // this element may not exist in a testing context, so prevent errors there
            }

            usSpinnerService.spin(contentLoadingSpinnerKey);
            // set a timeout to transition to an error state, or at least stop spinning, if
            // the state transition takes more than 15 seconds,
            // and possibly have an error message element always present just like spinner
        }
    });

    $transitions.onSuccess({}, () => {
        analyticsService.page();
        usSpinnerService.stop(contentLoadingSpinnerKey);
    });

    $transitions.onError({}, (transition: Transition) => {
        usSpinnerService.stop(contentLoadingSpinnerKey);
    });
});

export { app };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { MortgageService } from 'services/mortgage';
import { ModalService } from 'common/source/services/modal';
import { getMortgages, isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('mortgagee-clause', []);

interface MortgageeClauseController extends angular.IStrictController {
    title: string;
    icon: string;
    mortgages: Array<MortgageDetails>;
    policyInfo: LenderPolicyInfo;
    isCancelled: boolean;
    isEscrowCheckout: boolean;
    isSupportLogin: boolean;
    updateMortgageDisplay: () => void;
    addMortgagee: () => void;
    editMortgagee: (index: number) => void;
    removeMortgagee: (index: number) => void;
    presaveNumMortgages: number;
    getOrdinal: (number: number) => string;
}

module.component('mortgageeClause', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: MortgageeClauseController,
        modalService: ModalService,
        mortgageService: MortgageService,
        lenderPolicyInfoService: LenderPolicyInfoService,
        authenticationService: AuthenticationService,
        $rootScope: angular.IRootScopeService
    ) {
        'ngInject';

        this.$onInit = () => {
            this.isEscrowCheckout = this.policyInfo.checkout_data.payment_method === 'escrow';
            this.icon = 'document-with-mortgage';
            this.title = 'Mortgagee Clause';
            this.presaveNumMortgages = getMortgages(this.policyInfo).length;
            this.getOrdinal = mortgageService.getOrdinal;
            this.isCancelled = isPolicyCancelled(this.policyInfo);
            this.isSupportLogin = authenticationService.isSupportLogin();

            $rootScope.$on('lenderPolicyInfoChangesApplied', () => {
                this.policyInfo = lenderPolicyInfoService.getPolicyInfo() as LenderPolicyInfo;
                this.presaveNumMortgages = getMortgages(this.policyInfo).length;
            });
        };

        this.$onChanges = () => {
            this.mortgages = mortgageService.getMortgages();
        };

        this.addMortgagee = () => {
            const newIdx = this.mortgages.length + 1;
            modalService.openUncloseableModal('edit-mortgagee-clause', { newIdx });
        };

        this.removeMortgagee = (index: number) => {
            const mortgage = this.mortgages[index];
            if (this.presaveNumMortgages <= index || (mortgage.type !== 'first' && this.isSupportLogin)) {
                this.mortgages.splice(index, 1);
                for (let i = index; i < this.mortgages.length; i++) {
                    const mortgageType = mortgageService.getOrdinalText(i + 1);
                    if (mortgageType) {
                        this.mortgages[i].type = mortgageType;
                    }
                }
                this.policyInfo.property_data.mortgages.details = this.mortgages;
                lenderPolicyInfoService.setPolicyInfo(this.policyInfo);
            }
        };

        this.editMortgagee = (index: number) => {
            if (index >= 0) {
                modalService.openUncloseableModal('edit-mortgagee-clause', { index });
            }
        };
    }
});

export { module };

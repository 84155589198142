import * as angular from 'angular';
import * as _ from 'lodash';

export function capitalizeWords(string?: string) {
    if (!string) {
        return '';
    }

    return string.replace(/\b\w/g, (letter) => letter.toUpperCase());
}

const $filter: angular.IFilterService = angular.injector(['ng']).get('$filter');

export function formatAsDollars(value: number, decimalPlaces: number) {
    return $filter('currency')(value, '$', decimalPlaces);
}

export function formatAsWholeDollars(value: number) {
    return formatAsDollars(value, 0);
}

export function formatAsDollarsWithoutZeroCents(value: number) {
    const dollarsAndCents = formatAsDollars(value, 2);

    if (dollarsAndCents.substr(-3) === '.00') {
        return formatAsWholeDollars(value);
    } else {
        return dollarsAndCents;
    }
}

export function formatAsDollarsWithoutZeroCentsOrEmptyString(value: number) {
    return value ? formatAsDollarsWithoutZeroCents(value) : '';
}

export function formatAsQuantity(value: number, decimalPlaces: number = 0) {
    return $filter('number')(value, decimalPlaces);
}

export function formatAsQuantityWithoutZeroAfterDecimal(value: number) {
    const quantityAndFraction = formatAsQuantity(value, 2);

    if (quantityAndFraction.substr(-3) === '.00') {
        return formatAsQuantity(value, 0);
    } else {
        return quantityAndFraction;
    }
}

export function uppercaseFirstLetter(value: string) {
    return `${value[0].toUpperCase()}${value.slice(1)}`;
}

export function noWrapLastTwoWords(text?: string) {
    if (!text) {
        return '';
    }

    const lastSpaceIndex = _.lastIndexOf(text, ' ');
    const secondToLastSpaceIndex = _.lastIndexOf(text, ' ', lastSpaceIndex - 1);

    let formattedDescription = text.substr(0, secondToLastSpaceIndex + 1);
    formattedDescription += text.substr(secondToLastSpaceIndex + 1).replace(' ', '&nbsp;');

    return formattedDescription;
}

export function extractNumberFromDollarAmountString(string: string) {
    if (string.length >= 2 && string.charAt(0) === '$') {
        const stringWithoutDollarSignAndCommas = string.substring(1).replace(/,/g, '');
        return parseInt(stringWithoutDollarSignAndCommas, 10);
    } else {
        return null;
    }
}

export function extractTenDigitsFromPhoneNumber(phoneNumberOrEmail?: string) {
    if (!phoneNumberOrEmail) {
        return undefined;
    }

    const match = phoneNumberOrEmail.match(PHONE_NUMBER_PATTERN);

    if (match) {
        return match[1] + match[2] + match[3];
    } else {
        return undefined;
    }
}

// Given a valid email address, returning the parts that make up the email:
// user name, server name, and top level domain.
//
// Example:
//
// If input is:
// alvin@myhippo.com
//
// Returns:
// {
//   userName: alvin
//   mailServer: myhippo
//   top level domain (extension): com
// }
export function getEmailPartsFromEmailAddress(email: string) {
    const emailRegex = new RegExp(EMAIL_PATTERN);
    const emailParts = {
        userName: '',
        mailServer: '',
        topLevelDomain: ''
    };

    if (emailRegex.test(email)) {
        const emailSplitByAtSymbol = email.split('@');
        const domain = emailSplitByAtSymbol[1];

        emailParts.userName = emailSplitByAtSymbol[0];
        emailParts.mailServer = domain.split('.')[0];
        emailParts.topLevelDomain = domain.split('.')[1];
    }

    return emailParts;
}

// Returns a truncated version of the email. If character count of email
// excluding domain is 15 or less characters, then leave it alone.
// Otherwise, we will only show the last 3 characters of the user name and only
// show the last 5 characters of the mail server.
// E.g. alvin@myhippo.com => ...vin@...hippo.com
export function getTruncatedEmail(email: string) {
    if (email.length < 15) {
        return email;
    } else {
        const emailParts = getEmailPartsFromEmailAddress(email);

        if (emailParts.userName && emailParts.mailServer && emailParts.topLevelDomain) {
            const truncatedEmailUserName =
                emailParts.userName.length > 3 ? `...${emailParts.userName.substr(-3)}` : emailParts.userName;
            const truncatedMailServer =
                emailParts.mailServer.length > 5 ? `...${emailParts.mailServer.substr(-5)}` : emailParts.mailServer;
            return `${truncatedEmailUserName}@${truncatedMailServer}.${emailParts.topLevelDomain}`;
        } else {
            return '';
        }
    }
}

export const EMAIL_PATTERN = [
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+',
    '(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)',
    '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.',
    '[0-9]{1,3}\\.[0-9]{1,3}])|',
    '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
].join('');

export const PHONE_NUMBER_PATTERN = /^(?:\+1|1)?[-. ]*\(?([0-9]{3})\)?[-. ]*([0-9]{3})[-. ]*([0-9]{4})$/;

export function getFormattedPhoneNumber(number: string, format?: string) {
    // Default format - ( 1-800-123-4567 -> (800) 123-4567 )
    // Default format fallback - ( 1-800-123-4567 )
    // Period format ( 1-800-123-4567 -> 1.800.123.4567 )

    if (!PHONE_NUMBER_PATTERN.test(number)) {
        return '';
    } else if (format === 'period') {
        return number.replace(/-/g, '.');
    } else {
        return number.replace(PHONE_NUMBER_PATTERN, '($1) $2-$3');
    }
}

const module = angular.module('customerPortal.utilities.strings', []);

module.value('extractNumberFromDollarAmountString', extractNumberFromDollarAmountString);
module.value('formatAsDollarsWithoutZeroCents', formatAsDollarsWithoutZeroCents);
module.value('capitalizeWords', capitalizeWords);
module.value('extractTenDigitsFromPhoneNumber', extractTenDigitsFromPhoneNumber);
module.value('getEmailPartsFromEmailAddress', getEmailPartsFromEmailAddress);
module.value('getTruncatedEmail', getTruncatedEmail);
module.value('getFormattedPhoneNumber', getFormattedPhoneNumber);

import * as angular from 'angular';
import './styling.scss';
import 'angular-ui-mask';
import * as template from './template.html';
import { getLongNumericAmericanDateString } from 'common/source/utilities/dates';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import { HippoApiService } from 'services/hippoApi';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('fax-documents', []);

interface FaxDocumentsFormScope extends angular.IScope {
    faxDocumentsForm: angular.IFormController;
}
interface FaxDocumentsController extends angular.IStrictController {
    policyDocuments: Array<CheckedPolicyDocument>;
    lenderName: string;
    lenderEmail: string;
    faxNumber: string;
    isShowingServerError: boolean;
    submit: () => void;
    cancel: () => void;
    numberOfDocumentsSelected: () => number;
}

interface CheckedPolicyDocument extends PolicyDocument {
    isChecked: boolean;
    trackKey: 'eoi_document_url' | 'RCE_document_url' | 'policy_invoice_document_url';
}

const getValuationDoc = (documents: AggregateDocuments) => {
    if (Array.isArray(documents.valuation)) {
        if (documents.valuation.length) {
            return documents.valuation[documents.valuation.length - 1];
        }
    } else {
        return documents.valuation;
    }
};

module.component('faxDocuments', {
    template: template,
    controller: function(
        this: FaxDocumentsController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        modalService: ModalService,
        $element: angular.IAugmentedJQuery,
        authenticationService: AuthenticationService,
        hippoApiService: HippoApiService
    ) {
        let policyInfo: LenderPolicyInfo | undefined;

        const getFaxDocumentsForm = () => {
            const form = $element.find('form').scope() as FaxDocumentsFormScope;
            return form.faxDocumentsForm;
        };

        this.$onInit = () => {
            this.isShowingServerError = false;

            policyInfo = lenderPolicyInfoService.getPolicyInfo();
            this.policyDocuments = [];

            if (policyInfo) {
                const documents: AggregateDocuments = policyInfo.aggregate_documents;

                if (documents.eoi) {
                    this.policyDocuments.push({
                        name: `Evidence of Insurance (EOI) ${getLongNumericAmericanDateString(
                            documents.eoi.created_at
                        )}`,
                        url: documents.eoi.url,
                        testId: 'eoi-document',
                        trackKey: 'eoi_document_url',
                        isChecked: false
                    });
                }
                const valuationDoc = getValuationDoc(documents);
                if (valuationDoc) {
                    this.policyDocuments.push({
                        name: 'Home Rebuilding Cost Estimate (RCE)',
                        url: valuationDoc.url,
                        testId: 'rce-document',
                        trackKey: 'RCE_document_url',
                        isChecked: false
                    });
                }
                if (documents.escrow_invoice) {
                    this.policyDocuments.push({
                        name: 'Mortgage Bill Invoice',
                        url: documents.escrow_invoice.url,
                        testId: 'invoice-document',
                        trackKey: 'policy_invoice_document_url',
                        isChecked: false
                    });
                }
                if (documents.policy_invoice) {
                    this.policyDocuments.push({
                        name: 'Policy Invoice',
                        url: documents.policy_invoice.url,
                        testId: 'policy-invoice',
                        trackKey: 'policy_invoice_document_url',
                        isChecked: false
                    });
                }
            }
        };

        this.submit = () => {
            this.isShowingServerError = false;
            const faxFocumentsForm = getFaxDocumentsForm();
            faxFocumentsForm.$setSubmitted();

            if (faxFocumentsForm.$invalid) {
                return;
            }
            if (this.numberOfDocumentsSelected() === 0) {
                return;
            }

            const eventPayload: FaxData = {
                lender_name: this.lenderName,
                lender_email: this.lenderEmail,
                fax_number: this.faxNumber
            };
            this.policyDocuments.forEach((doc) => {
                if (doc.isChecked) {
                    eventPayload[doc.trackKey] = doc.url;
                }
            });

            const loginInfo = authenticationService.getAuthenticationParameters();
            hippoApiService
                .sendFax(loginInfo, eventPayload)
                .then(() => {
                    modalService.closeAllDialogs();
                })
                .catch((e) => {
                    this.isShowingServerError = true;
                });
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };

        this.numberOfDocumentsSelected = () => {
            let docCount = 0;
            this.policyDocuments.forEach((doc) => {
                if (doc.isChecked) {
                    docCount += 1;
                }
            });
            return docCount;
        };
    }
});

export { module };

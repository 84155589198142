import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';
import * as LoginComponents from './components';

const module = angular.module('login-page', [LoginComponents.module.name]);

interface LoginPageController extends angular.IStrictController {
    attemptedLogin?: boolean;
    loginAttemptSucceeded?: boolean;
    loginAttemptFailed?: boolean;
    unknownLoginError?: boolean;

    hasLoginError(): boolean;
}

module.component('loginPage', {
    template: template,
    bindings: {},
    controller: function(this: LoginPageController) {
        this.hasLoginError = () => {
            return !!(this.loginAttemptFailed || this.unknownLoginError);
        };

        this.$onInit = () => {};
    }
});

export { module };

import { UsSpinnerConfigProvider, UsSpinnerOptions } from '../../types/angular-spinner';

export default function usSpinnerConfig(usSpinnerConfigProvider: UsSpinnerConfigProvider) {
    'ngInject';

    const options: UsSpinnerOptions = {
        lines: 12, // The number of lines to draw
        length: 80, // The length of each line
        width: 15, // The line thickness
        radius: 64, // The radius of the inner circle
        scale: 0.2, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        color: '#19dd84', // CSS color or array of colors
        fadeColor: 'transparent', // CSS color or array of colors
        speed: 1.3, // Rounds per second
        rotate: 0, // The rotation offset
        animation: 'spinner-line-fade-default', // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        className: 'spinner', // The CSS class to assign to the spinner
        top: '50%', // Top position relative to parent
        left: '50%', // Left position relative to parent
        // shadow: '0 0 1px transparent', // Box-shadow for the lines
        position: 'absolute' // Element positioning
    };

    usSpinnerConfigProvider.setTheme('loadingButton', {
        radius: 20,
        length: 10,
        color: 'white'
    });
    usSpinnerConfigProvider.setDefaults(options);
}

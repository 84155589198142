import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';

const module = angular.module('icon-title-section', []);

interface IconTitleSectionController extends angular.IStrictController {
    bottomBorder: string;
    sectionTitle: string;
    isTranscludePresent: () => boolean;
}

module.component('iconTitleSection', {
    template: template,
    bindings: {
        sectionTitle: '<',
        icon: '<',
        bottomBorder: '@'
    },
    transclude: true,
    controller: function(this: IconTitleSectionController, $element: angular.IAugmentedJQuery) {
        'ngInject';

        this.$onInit = () => {
            if (this.bottomBorder === 'true') {
                $element.addClass('with-bottom-border');
            }
            if (this.sectionTitle) {
                $element.addClass('with-section-title');
            }

            this.isTranscludePresent = () => {
                return $element.find('ng-transclude').html() !== '';
            };
        };
    }
});

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';
import { EMAIL_PATTERN } from 'common/source/utilities/strings';

const module = angular.module('invalid-email', []);

module.directive('invalidEmail', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(
            this: angular.IDirective,
            $scope: angular.IScope,
            $element: JQLite,
            attrs: angular.IAttributes,
            ctrl?: angular.IController
        ) {
            if (this.require === 'ngModel') {
                (ctrl as angular.INgModelController).$validators.invalidEmail = (modelValue: any, viewValue: any) => {
                    const emailRegex = new RegExp(EMAIL_PATTERN);
                    if (!_.isEmpty(viewValue)) {
                        return !!emailRegex.test(viewValue);
                    } else {
                        return false;
                    }
                };
            }
        }
    };
});

export { module };

export type StateName = 'login' | 'policy' | 'logout' | 'support' | 'login-by-address';

export const LOGIN_STATE: StateName = 'login';
export const POLICY_STATE: StateName = 'policy';
export const LOGOUT_STATE: StateName = 'logout';
export const SUPPORT_STATE: StateName = 'support';
export const LOGIN_BY_ADDRESS_STATE: StateName = 'login-by-address';

export function getLoginSref() {
    return LOGIN_STATE;
}

export function getPolicySref() {
    return POLICY_STATE;
}

export function getLogoutSref() {
    return LOGOUT_STATE;
}

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import * as _ from 'lodash';

const module = angular.module('payment-method', []);

import { ModalService } from 'common/source/services/modal';
import { getFormattedPaymentMethodName } from 'common/source/utilities/policyInfoDisplay';
import { isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';
import { AuthenticationService } from 'services/authentication';

interface PaymentMethodController extends angular.IStrictController {
    title: string;
    icon: string;
    paymentType: string;
    paymentFrequency: string | undefined;
    escrowCompany: string | undefined;
    policyInfo: LenderPolicyInfo;
    isCancelled: boolean;
    isSupportLogin: boolean;
    isPaidInFull: boolean;
    edit: () => void;
    updateDisplay: () => void;
}

module.component('paymentMethod', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(
        this: PaymentMethodController,
        modalService: ModalService,
        authenticationService: AuthenticationService
    ) {
        'ngInject';

        const getPaymentFrequency = () => {
            const paymentFrequency = this.policyInfo.checkout_data.payment_frequency;
            if (this.policyInfo.checkout_data.payment_method === 'cc' && paymentFrequency) {
                return _.startCase(paymentFrequency);
            }
            return '';
        };

        const getEscrowCompany = () => {
            const lenderName = this.policyInfo.checkout_data.lender_name;
            if (this.policyInfo.checkout_data.payment_method === 'escrow' && lenderName) {
                return lenderName;
            }
            return '';
        };

        this.$onChanges = () => {
            this.paymentType = getFormattedPaymentMethodName(this.policyInfo);
            this.paymentFrequency = getPaymentFrequency();
            this.escrowCompany = getEscrowCompany();
            this.isPaidInFull =
                this.policyInfo.payment_status === 'paid' &&
                this.policyInfo.checkout_data.payment_frequency === 'annually';
        };

        this.edit = () => {
            const isCredit = this.policyInfo.checkout_data.payment_method === 'cc';
            modalService.openUncloseableModal(isCredit ? 'edit-credit-card-contact-support' : 'edit-escrow-details');
        };

        this.$onInit = () => {
            this.icon = 'green-money';
            this.title = 'Payment Method';
            this.isCancelled = isPolicyCancelled(this.policyInfo);
            this.isSupportLogin = authenticationService.isSupportLogin();
        };
    }
});

export { module };

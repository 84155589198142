import * as angular from 'angular';
import { HippoApiService } from 'services/hippoApi';

export const module = angular.module('analyticsService', []);

export class AnalyticsService {
    private hippoApiService: HippoApiService;
    private $stateParams: StateParams;

    constructor(hippoApiService: HippoApiService, $stateParams: StateParams) {
        this.hippoApiService = hippoApiService;
        this.$stateParams = $stateParams;
    }

    track(eventName: string, properties: object = {}) {
        if (window.heap) {
            window.heap.track(eventName, properties);
        }
    }

    identify(userId: string, properties: object = {}) {
        if (window.FS) {
            window.FS.identify(userId, properties);
        }

        if (window.ineum) {
            window.ineum('user', userId);
        }

        if (window.heap) {
            if (userId) {
                window.heap.identify(userId);
            }
            window.heap.addUserProperties(properties);
        }
    }

    page() {
        const policyIndex = this.$stateParams.policyIndex;
        const url = window.location.pathname;

        if (window.ineum) {
            window.ineum('page', url);
        }

        // this.hippoApiService.internalTracking({
        //     type: 'navigate',
        //     path: url,
        //     leadId: (policyIndex as any) as string
        // });
    }
}

module.factory('analyticsService', function(hippoApiService: HippoApiService, $stateParams: StateParams) {
    return new AnalyticsService(hippoApiService, $stateParams);
});

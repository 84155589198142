import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import * as _ from 'lodash';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import { hasDataChangedOnForm } from 'common/source/utilities/policyInfoHelper';

const module = angular.module('edit-property-address', []);

interface PropertyAddressFormScope extends angular.IScope {
    propertyAddressForm: angular.IFormController;
}
interface EditPropertyAddressController extends angular.IStrictController {
    address: AddressWithCounty;
    city: string;
    save: () => void;
    cancel: () => void;
    clearCityInputValue: () => void;
    hasDataChangedOnForm: () => boolean;
}

module.component('editPropertyAddress', {
    template: template,
    controller: function(
        this: EditPropertyAddressController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        modalService: ModalService,
        $element: angular.IAugmentedJQuery,
        $timeout: angular.ITimeoutService
    ) {
        let policyInfo: LenderPolicyInfo | undefined;
        const deepFieldsMap: Array<[string, string]> = [['property_data.address.city', 'city']];

        const focusCityInput = () => {
            const cityInput = document.getElementById('city') as HTMLElement;

            if (cityInput) {
                cityInput.focus();
            }
        };

        const getPropertyAddressForm = () => {
            const form = $element.find('form').scope() as PropertyAddressFormScope;
            return form.propertyAddressForm;
        };

        this.$onInit = () => {
            policyInfo = lenderPolicyInfoService.getPolicyInfo();

            if (policyInfo) {
                this.address = policyInfo.property_data.address;
                this.city = this.address.city;
            }

            $timeout(() => {
                focusCityInput();
            });
        };

        this.hasDataChangedOnForm = () => hasDataChangedOnForm(policyInfo, this, deepFieldsMap);

        this.save = () => {
            const propertyAddressForm = getPropertyAddressForm();
            propertyAddressForm.$setSubmitted();

            if (propertyAddressForm.$invalid) {
                return focusCityInput();
            }

            if (policyInfo) {
                policyInfo.property_data.address = {
                    ...this.address,
                    city: this.city
                };

                lenderPolicyInfoService.setPolicyInfo(policyInfo);
                modalService.closeAllDialogs();
            }
        };

        this.clearCityInputValue = () => {
            const propertyAddressForm = getPropertyAddressForm();
            this.city = '';
            propertyAddressForm.city.$setViewValue();
            focusCityInput();
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as angular from 'angular';
import { StateService } from '@uirouter/angularjs';

import * as states from 'components/navigation/shared/states';

export const module = angular.module('navigationService', []);

const lenderPortalBasePath = '/lender';

export class NavigationService {
    private $state: StateService;
    private $window: angular.IWindowService;

    constructor($state: StateService, $window: angular.IWindowService) {
        this.$state = $state;
        this.$window = $window;
    }

    getBaseUrl() {
        const location = this.$window.location;

        const protocol = location.protocol;
        const host = location.host;

        let path = '';

        if (location.pathname.substr(0, lenderPortalBasePath.length) === lenderPortalBasePath) {
            path = lenderPortalBasePath;
        }

        return `${protocol}//${host}${path}`;
    }

    getLoginUrl() {
        return this.getBaseUrl() + this.$state.get(states.LOGIN_STATE).url;
    }

    toLoginPage(loginType: LoginType) {
        if (loginType === 'loginByAddress') {
            this.$state.go(states.LOGIN_BY_ADDRESS_STATE);
        } else if (loginType === 'loginSupport') {
            this.$state.go(states.SUPPORT_STATE);
        } else {
            this.$state.go(states.LOGIN_STATE);
        }
    }

    toLogoutPage() {
        this.$state.go(states.LOGOUT_STATE);
    }

    toPolicyPage() {
        this.$state.go(states.POLICY_STATE);
    }
}

module.factory('navigationService', function($state: StateService, $window: angular.IWindowService) {
    'ngInject';

    return new NavigationService($state, $window);
});

import * as angular from 'angular';

import * as AppHeaderComponent from './navigation';
import * as AppFooterComponent from 'common/source/components/app-footer';
import * as HippoButtonComponent from 'common/source/components/hippo-button';
import * as HippoIconComponent from 'common/source/components/hippo-icon';
import * as InfoTooltipIconComponent from 'common/source/components/info-icon-with-tooltip';
import * as IconTitleSectionComponent from 'common/source/components/icon-title-section';
import * as TitleSectionComponent from 'common/source/components/title-section';
import * as LogoutComponent from 'common/source/components/logout';
import * as HippoInputComponent from 'common/source/components/hippo-input';
import * as HippoCheckboxComponent from 'common/source/components/hippo-check-box';
import * as StateDropdown from 'common/source/components/state-dropdown';
import * as LenderAutocomplete from './lender-autocomplete';
import * as MortgageEscrowCheckBox from './mortgage-escrow-check-box';
import * as HippoNumberComponent from 'common/source/components/hippo-number';
import * as HippoAvailabilityComponent from 'common/source/components/hippo-availability';

const module = angular.module('lenderPortalComponents', [
    AppHeaderComponent.module.name,
    AppFooterComponent.module.name,
    HippoButtonComponent.module.name,
    LogoutComponent.module.name,
    HippoIconComponent.module.name,
    InfoTooltipIconComponent.module.name,
    LogoutComponent.module.name,
    IconTitleSectionComponent.module.name,
    TitleSectionComponent.module.name,
    HippoInputComponent.module.name,
    HippoCheckboxComponent.module.name,
    StateDropdown.module.name,
    LenderAutocomplete.module.name,
    MortgageEscrowCheckBox.module.name,
    HippoNumberComponent.module.name,
    HippoAvailabilityComponent.module.name
]);

export { module };

import * as angular from 'angular';
import * as LoginPage from './login';
import * as LoginByAddressPage from './login-by-address';
import * as SupportLoginPage from './support';
import * as PolicyPage from './policy';
import * as ContactUsPage from './contact-us';
import * as LogoutPage from 'common/source/pages/logout';

const module = angular.module('lenderPortalPages', [
    LoginPage.module.name,
    LoginByAddressPage.module.name,
    SupportLoginPage.module.name,
    PolicyPage.module.name,
    LogoutPage.module.name,
    ContactUsPage.module.name
]);

export { module };

import * as angular from 'angular';

const module = angular.module('limit-length', []);
import * as hippoConstFactory from 'common/hippoConst.js';
const HIPPO_CONSTS = hippoConstFactory();
const maxInputLength = HIPPO_CONSTS.INPUT_MAX_LENGTH;

module.directive('limitLength', function() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(this: angular.IDirective, $scope: angular.IScope, $element: JQLite, $attr: angular.IAttributes) {
            if (this.require === 'ngModel') {
                $attr.$set('maxlength', $attr.limitLength || maxInputLength);
            }
        }
    };
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import * as _ from 'lodash';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { MortgageService } from 'services/mortgage';
import { ModalService } from 'common/source/services/modal';
import {
    getMortgages,
    hasCreditCardPaymentMethod,
    hasDataChangedOnForm
} from 'common/source/utilities/policyInfoHelper';

const module = angular.module('edit-mortgage-clause', []);

interface EditMortgageClauseController extends angular.IStrictController {
    mortgage: MortgageDetails;
    data: {
        ngDialogId: string;
        index?: number;
        newIdx?: number;
    };
    index: number;
    mode: string;
    position: number;
    save: () => void;
    cancel: () => void;
    getOrdinal: (number: number) => string;
    hasDataChangedOnForm: () => boolean;
    prefillLenderSelect: (lender: KnownLenderInfo) => void;
    mortgageForm: angular.IFormController;
}

interface MortgageLendersFormScope extends angular.IScope {
    mortgageeClauseForm: angular.IFormController;
}

module.component('editMortgageeClause', {
    template: template,
    bindings: {
        data: '<'
    },
    controller: function(
        this: EditMortgageClauseController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        $element: angular.IAugmentedJQuery,
        modalService: ModalService,
        mortgageService: MortgageService,
        $timeout: angular.ITimeoutService
    ) {
        let policyInfo: LenderPolicyInfo | undefined;
        let deepFieldsMap: Array<[string, string]>;

        this.$onInit = () => {
            policyInfo = lenderPolicyInfoService.getPolicyInfo();
            this.index = this.data.index !== undefined ? this.data.index : -1;
            this.mode = this.index >= 0 ? 'edit' : 'add';
            this.getOrdinal = mortgageService.getOrdinal;
            const mortgages = getMortgages(policyInfo as LenderPolicyInfo);
            this.mortgage =
                this.mode === 'add' ? mortgageService.createNewMortgage() : _.cloneDeep(mortgages[this.index]);
            const position = this.data.newIdx || this.index + 1;
            this.position = position;
            deepFieldsMap = [
                [`property_data.mortgages.details.${this.index}.zip`, 'mortgage.zip'],
                [`property_data.mortgages.details.${this.index}.city`, 'mortgage.city'],
                [`property_data.mortgages.details.${this.index}.state`, 'mortgage.state'],
                [`property_data.mortgages.details.${this.index}.street`, 'mortgage.street'],
                [`property_data.mortgages.details.${this.index}.street2`, 'mortgage.street2'],
                [`property_data.mortgages.details.${this.index}.name`, 'mortgage.name'],
                [`property_data.mortgages.details.${this.index}.number`, 'mortgage.number']
            ];

            $timeout(() => {
                const inputElements = $element.find('input');
                inputElements
                    .on('focus', (evt) => {
                        evt.target!.parentElement!.classList.add('is-focused');
                    })
                    .on('blur', (evt) => {
                        evt.target!.parentElement!.classList.remove('is-focused');
                    });

                const form = $element.find('form').scope() as MortgageLendersFormScope;
                this.mortgageForm = form.mortgageeClauseForm;
            });
        };

        this.hasDataChangedOnForm = () => hasDataChangedOnForm(policyInfo, this, deepFieldsMap);

        this.prefillLenderSelect = (lender: KnownLenderInfo) => {
            Object.keys(lender.info).forEach((prop) => {
                const prefillValue = _.get(lender.info, prop);
                _.set(this.mortgage, prop, prefillValue);
            });
            this.mortgageForm.$setDirty();
            // Focus on loan number input
            $timeout(() => {
                const loanSelector = 'form[name="mortgageeClauseForm"] input[name="number"]';
                const loanInput = document.querySelector(loanSelector) as HTMLInputElement;
                loanInput!.focus();
            });
        };

        this.save = () => {
            this.mortgageForm.$setSubmitted();
            if (policyInfo && this.mortgageForm.$valid) {
                const mortgages = getMortgages(policyInfo);
                if (this.mode === 'edit') {
                    mortgages.splice(this.index, 1, this.mortgage);
                } else {
                    mortgages.push(this.mortgage);
                }
                // Update payment method if mortgage is first and payment method is escrow
                if (mortgageService.isFirstLien(this.mortgage) && !hasCreditCardPaymentMethod(policyInfo)) {
                    const updatedCheckoutData = {
                        ...policyInfo.checkout_data,
                        ...mortgageService.covertMortgageDetailsToEscrowDetails(this.mortgage)
                    };
                    policyInfo.checkout_data = updatedCheckoutData;
                }

                lenderPolicyInfoService.setPolicyInfo(policyInfo);
                modalService.closeAllDialogs();
            } else if (this.mortgageForm.$invalid) {
                $timeout(() => {
                    const errorInput = document.querySelector('input.ng-invalid') as HTMLElement;
                    if (errorInput) {
                        errorInput.focus();
                    }
                });
            }
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as _ from 'lodash';
import * as moment from 'moment';
import idx from 'idx';
import * as angular from 'angular';
import { extractNumberFromDollarAmountString } from '../strings/index';
import { AMERICAN_DATE_FORMAT, getLongNumericAmericanDateString, getMomentFromHippoDateString } from '../dates/index';

export function getHomeCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.a.value;
}

export function getSeparateStructureCoverageAmount(policyInfo: AnyPolicyInfo) {
    return idx(policyInfo, (obj) => obj.adjustments.b!.value);
}

export function getExtendedReplacementCostCoverageAmount(policyInfo: AnyPolicyInfo) {
    const extendedReplacementCostCoverage = idx(policyInfo, (obj) => obj.adjustments.extended_rebuilding_cost!.value);

    if (typeof extendedReplacementCostCoverage === 'number') {
        return extendedReplacementCostCoverage * getHomeCoverageAmount(policyInfo);
    } else {
        return null;
    }
}

export function getBelongingsCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.c.value;
}

export function getLossOfUseCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.d.value;
}

export function getReplacementCostCoverage(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.personal_property_replacement_cost.value;
}

export function getOrdinanceProtectionCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.ordinance_or_law.value * getHomeCoverageAmount(policyInfo);
}

export function getMineSubsidenceCoverage(policyInfo: AnyPolicyInfo) {
    return idx(policyInfo, (obj) => obj.adjustments.mine_subsidence!.value);
}

export function getSinkholeCollapseCoverage(policyInfo: AnyPolicyInfo) {
    return idx(policyInfo, (obj) => obj.adjustments.sinkhole_collapse!.value);
}

export function getPersonalLiabilityCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.e.value;
}

export function getMedicalPaymentCoverageAmount(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.f.value;
}

export function getGeneralDeductibleAmount(policyInfo: AnyPolicyInfo) {
    const deductibleObject = policyInfo.adjustments.deductible;
    return getDeductibleAmountFromAdjustmentOptionsAndValue(policyInfo, deductibleObject);
}

export function getSmartHomeStarterKit(policyInfo: AnyPolicyInfo) {
    return policyInfo.property_data.smart_home_starter_kit;
}

export function hasSmartHomeStarterKit(policyInfo: AnyPolicyInfo) {
    return !!(policyInfo.property_data.smart_home_starter_kit || policyInfo.property_data.smart_home_kit_activated);
}

export function getSmartHomeKitProvider(policyInfo: AnyPolicyInfo) {
    // kit_details and selected_kit are the new place to find the provider but need to support primary kit for older policies
    // and as a fallback option.  The format from selected_kit is <provider>_<tier> ie: kangaroo_self
    return _.get(policyInfo, 'smart_home.state_summary.kit_details[0].provider') ||
        _.get(policyInfo, 'smart_home.ordering_info.selected_kit', '').split('_')[0] ||
        _.get(policyInfo, 'smart_home.primary_kit.provider')
}

export function isAdvancedSmartHomeKit(policyInfo: AnyPolicyInfo) {
    return !!policyInfo.property_data.smart_home_pro_monitoring_kit;
}

export function isAdvancedSmartHomeKitState(states: string[], policyInfo: AnyPolicyInfo) {
    return _.includes(states, policyInfo.property_data.address.state);
}

export function getSmartHomeStarterKitActivationDate(policyInfo: AnyPolicyInfo) {
    if (_.get(policyInfo, 'using_new_smart_home')) {
        return _.get(policyInfo, 'smart_home.state_summary.kit_details[0].initial_activation_date');
    } else {
        return _.get(policyInfo, 'smart_home.primary_kit.activation_date');
    }
}

export function getAutoInsurancePartner(policyInfo: AnyPolicyInfo) {
    return _.get(policyInfo, 'auto_insurance_partner.partner_name');
}

export function getAutoInsurancePartnerStatus(policyInfo: AnyPolicyInfo) {
    return _.get(policyInfo, 'auto_insurance_partner.status');
}

export function getSmartHomeStarterKitActivationDays(policyInfo: CustomerPolicyInfo) {
    return policyInfo.calculated_fields.smart_home_starter_kit_activation_days;
}

export function getSmartHomeStarterKitIsActivated(policyInfo: CustomerPolicyInfo) {
    return policyInfo.property_data.smart_home_kit_activated;
}

export function getSmartHomeStarterKitDiscountRemovalDate(policyInfo: CustomerPolicyInfo) {
    const effectiveDateMoment = getMomentFromHippoDateString(getEffectiveDate(policyInfo));
    return effectiveDateMoment.add(getSmartHomeStarterKitActivationDays(policyInfo), 'days');
}

export function isPastActivationDatePeriod(policyInfo: CustomerPolicyInfo) {
    const removalDate = getSmartHomeStarterKitDiscountRemovalDate(policyInfo);
    return removalDate.diff(moment()) < 0;
}

export function getDeductibleAmountFromAdjustmentOptionsAndValue(
    policyInfo: AnyPolicyInfo,
    deductible: AdjustmentOptionsAndValue
): number | null {
    if (!deductible) {
        return null;
    } else {
        const deductibleValue = deductible.value;

        if (deductibleValue > 0 && deductibleValue < 100) {
            return (deductibleValue * policyInfo.adjustments.a.value) / 100;
        } else {
            const optionValue = deductible.options[deductibleValue];

            if (typeof optionValue === 'number') {
                return optionValue;
            } else if (typeof optionValue === 'string') {
                return extractNumberFromDollarAmountString(optionValue) || deductibleValue;
            } else {
                throw new Error('Unexpected option value type.');
            }
        }
    }
}

export function getCoverageAmountFromAdjustmentOptionsAndValue(
    policyInfo: AnyPolicyInfo,
    coverageAdjustment: AdjustmentOptionsAndValue
) {
    if (!coverageAdjustment) {
        return null;
    } else {
        const optionKey = coverageAdjustment.value;
        const optionValue = coverageAdjustment.options[optionKey];

        if (typeof optionValue === 'number') {
            return optionValue;
        } else if (typeof optionValue === 'string') {
            return extractNumberFromDollarAmountString(optionValue) || optionKey;
        } else {
            throw new Error('Unexpected option value type.');
        }
    }
}

export function getRoofCoverage(policyInfo: AnyPolicyInfo) {
    return policyInfo.adjustments.acv_on_roof.value;
}

export function getWaterBackupCoverageAmount(policyInfo: AnyPolicyInfo) {
    const waterBackupCoverageAmount = policyInfo.adjustments.water_backup;

    if (waterBackupCoverageAmount) {
        return getCoverageAmountFromAdjustmentOptionsAndValue(policyInfo, waterBackupCoverageAmount);
    } else {
        return undefined;
    }
}

export function getFoundationAndWaterCoverageAmount(policyInfo: AnyPolicyInfo) {
    const foundationAndWaterAdjustment = policyInfo.adjustments.foundation;

    if (foundationAndWaterAdjustment) {
        return getCoverageAmountFromAdjustmentOptionsAndValue(policyInfo, foundationAndWaterAdjustment);
    } else {
        return undefined;
    }
}

export function getHurricaneDeductibleAmount(policyInfo: AnyPolicyInfo): number | null {
    const hurricaneDeductibleObject = policyInfo.adjustments.hurricane_deductible;
    return getDeductibleAmountFromAdjustmentOptionsAndValue(policyInfo, hurricaneDeductibleObject);
}

export function getWindDeductibleAmount(policyInfo: AnyPolicyInfo): number | null {
    const windDeductibleObject = policyInfo.adjustments.wind_deductible;
    return getDeductibleAmountFromAdjustmentOptionsAndValue(policyInfo, windDeductibleObject);
}

export function getNextDuePayment(policyInfo: CustomerPolicyInfo): PaymentDueWithStatus | undefined {
    if (policyInfo.payment_status !== 'paid') {
        return {
            date: policyInfo.payment_due_date,
            status: policyInfo.payment_status,
            sum: policyInfo.payment_amount,
            paid: false
        };
    }
}

export function getSortedPastPaidPayments(policyInfo: CustomerPolicyInfo): Array<PastCharge> {
    const charges = policyInfo.charges
        .filter((charge: Charge) => {
            return charge.result;
        })
        .map(
            (charge: Charge): PastCharge => {
                return {
                    date: charge.charged_at,
                    sum: charge.sum,
                    paymentMethod: charge.payment_method
                };
            }
        );

    // Sort by payment date (most recent first)
    return _.orderBy(
        charges,
        (pastCharge) => {
            return getMomentFromHippoDateString(pastCharge.date).valueOf();
        },
        ['desc']
    );
}

export function getCreditCardExpirationMoment(policyInfo: AnyPolicyInfo) {
    return moment()
        .month(policyInfo.checkout_data.cc_info.exp_month - 1)
        .year(policyInfo.checkout_data.cc_info.exp_year);
}

export function getRenewalDate(policyInfo: AnyPolicyInfo) {
    // Renewal date is on the same day as expiration date.
    return getExpirationDate(policyInfo);
}

export function getExpirationDate(policyInfo: AnyPolicyInfo) {
    // Expiration date is 1 year after effective date at 12:01 am.
    return getLongNumericAmericanDateString(policyInfo.expiration_date);
}

export function isEligibleForWindCoverage(policyInfo: CustomerPolicyInfo) {
    return policyInfo.product !== 'ho6' && isWindCoverageExcluded(policyInfo) && !hasActiveWindCoverage(policyInfo);
}

export function shouldOfferOptionalFloodCoverage(policyInfo: CustomerPolicyInfo) {
    return policyInfo.property_data.address.state === 'nj';
}

export function isWindCoverageExcluded(policyInfo: CustomerPolicyInfo) {
    return !!policyInfo.calculated_fields.wind_exclusion;
}

export function isHomePolicy(policyInfo: AnyPolicyInfo) {
    return policyInfo.product === 'ho3';
}

export function isCondoPolicy(policyInfo: AnyPolicyInfo) {
    return policyInfo.product === 'ho6';
}

export function isDp3Policy(policyInfo: AnyPolicyInfo) {
    return policyInfo.product === 'dp3';
}

export function isPolicyCancelled(policyInfo: AnyPolicyInfo) {
    return !!(
        _(['terminated', 'expired']).includes(policyInfo.status)
    );
}

export function getPolicyCancellationDetails(policyInfo: LenderPolicyInfo) {
    return policyInfo.cancellation;
}

export function getEvidenceOfInsuranceDocument(policyInfo: AnyPolicyInfo) {
    if (policyInfo.aggregate_documents.eoi) {
        return policyInfo.aggregate_documents.eoi.url;
    } else {
        return null;
    }
}

export function getPaymentMethod(policyInfo: AnyPolicyInfo) {
    return policyInfo.checkout_data.payment_method;
}

export function getEffectiveDate(policyInfo: AnyPolicyInfo) {
    return policyInfo.effective_date;
}

export function getPaymentFrequency(policyInfo: AnyPolicyInfo): PaymentFrequency {
    return policyInfo.checkout_data.payment_frequency || 'annually';
}

export function getAutoRenewal(policyInfo: AnyPolicyInfo) {
    return policyInfo.checkout_data.auto_renewal;
}

export function getScheduledPersonalPropertyDetails(policyInfo: CustomerPolicyInfo) {
    return policyInfo.optional_coverages.scheduled_personal_property_details;
}

export function getMortgages(policyInfo: AnyPolicyInfo) {
    return policyInfo.property_data.mortgages.details;
}

export function getEarthquakeQuotePremium(policyInfo: CustomerPolicyInfo) {
    return policyInfo.calculated_fields.eq_quote_premium;
}

function getPartnerProduct(policyInfo: CustomerPolicyInfo, productType: string) {
    if (typeof policyInfo.partner_products === 'undefined') {
        return null;
    }

    return _.find(policyInfo.partner_products, (partnerProduct) => {
        return partnerProduct.type === productType && partnerProduct.status === 'active';
    });
}

export function getActiveEarthquakePartnerProduct(policyInfo: CustomerPolicyInfo) {
    return getPartnerProduct(policyInfo, 'earthquake');
}

export function getActiveWindPartnerProduct(policyInfo: CustomerPolicyInfo) {
    return getPartnerProduct(policyInfo, 'wind');
}

export function getActiveFloodPartnerProduct(policyInfo: CustomerPolicyInfo) {
    return getPartnerProduct(policyInfo, 'flood');
}

export function hasActiveWindCoverage(policyInfo: CustomerPolicyInfo) {
    const windPartnerProduct = getActiveWindPartnerProduct(policyInfo);
    const hasActiveWindCoverageFromPartner = windPartnerProduct && windPartnerProduct.status === 'active';
    const hasWindCoverageFromHippo = policyInfo.optional_coverages.wind_coverage;

    return hasActiveWindCoverageFromPartner || hasWindCoverageFromHippo;
}

export function requiresInspection(policyInfo: CustomerPolicyInfo) {
    return !!policyInfo.inspection;
}

export function hasMortgage(policyInfo: AnyPolicyInfo) {
    return !policyInfo.property_data.no_mortgage_discount;
}

export function hasCreditCardPaymentMethod(policyInfo: AnyPolicyInfo) {
    return policyInfo.checkout_data.payment_method === 'cc';
}

export function selectedWindMitigationDiscount(policyInfo: CustomerPolicyInfo) {
    return (
        typeof policyInfo.property_data.wind_mitigation === 'string' &&
        policyInfo.property_data.wind_mitigation !== 'other'
    );
}

export function requiresWindMitigationCertification(policyInfo: CustomerPolicyInfo) {
    const windMitigationCertificationWasReceived = !!policyInfo.windstorm_mitigation_verification_received;
    return selectedWindMitigationDiscount(policyInfo) && !windMitigationCertificationWasReceived;
}

export function getFullName(policyInfo: AnyPolicyInfo) {
    const firstName = policyInfo.personal_information.first_name;
    const lastName = policyInfo.personal_information.last_name;

    return `${firstName} ${lastName}`;
}

// This function is to take a value from PolicyInfo can normalize it to the value it would be if it had gone through a form input
function getNormalizedDataForFormInputComparison(data: Object, key: string) {
    const val = _.get(data, key);
    if (val === undefined) {
        return '';
    }
    return val;
}

export function hasDataChangedOnForm(
    policyInfo: CustomerPolicyInfo | LenderPolicyInfo | undefined,
    formData: Object,
    deepFieldsMap: [string, string][]
): boolean {
    if (policyInfo) {
        for (let map of deepFieldsMap) {
            let oldVal = getNormalizedDataForFormInputComparison(policyInfo, map[0]);
            let newVal = getNormalizedDataForFormInputComparison(formData, map[1]);
            if (newVal != oldVal) {
                return true;
            }
        }
    }
    return false;
}

// the following is to make this code accessible to unit tests

const module = angular.module('customerPortal.utilities.policyInfoHelper', []);

module.value('getHurricaneDeductibleAmount', getHurricaneDeductibleAmount);
module.value('getWindDeductibleAmount', getWindDeductibleAmount);
module.value('getFoundationAndWaterCoverageAmount', getFoundationAndWaterCoverageAmount);
module.value('getSortedPastPaidPayments', getSortedPastPaidPayments);

import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';
import * as LoginByAddressComponents from './components';

const module = angular.module('login-by-address-page', [LoginByAddressComponents.module.name]);

interface LoginByAddressPageController extends angular.IStrictController {
    attemptedLogin?: boolean;
    loginAttemptSucceeded?: boolean;
    loginAttemptFailed?: boolean;
    unknownLoginError?: boolean;

    hasLoginError(): boolean;
}

module.component('loginByAddressPage', {
    template: template,
    bindings: {},
    controller: function(this: LoginByAddressPageController) {
        this.hasLoginError = () => {
            return !!(this.loginAttemptFailed || this.unknownLoginError);
        };

        this.$onInit = () => {};
    }
});

export { module };

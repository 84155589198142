import * as angular from 'angular';

import * as IsFutureDateDirective from './is-future-date';
import * as IsPastDateDirective from './is-past-date';
import * as InvalidEffectiveDate from './invalid-effective-date';
import * as InvalidStreet from './invalid-street';
import * as InvalidZipcode from 'common/source/directives/invalid-zipcode';
import * as InvalidEmail from './invalid-email';
import * as InvalidDateOfBirth from './invalid-date-of-birth';
import * as NumberAndDashOnly from 'common/source/directives/number-and-dash-only';
import * as NumberOnly from 'common/source/directives/number-only';
import * as LimitLength from 'common/source/directives/limit-length';
import * as DateInput from 'common/source/directives/date-input';

const module = angular.module('lenderPortalDirectives', [
    IsFutureDateDirective.module.name,
    IsPastDateDirective.module.name,
    InvalidEffectiveDate.module.name,
    InvalidZipcode.module.name,
    InvalidStreet.module.name,
    InvalidEmail.module.name,
    InvalidDateOfBirth.module.name,
    NumberAndDashOnly.module.name,
    NumberOnly.module.name,
    LimitLength.module.name,
    DateInput.module.name
]);

export { module };

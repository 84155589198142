import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

const module = angular.module('base-modal', []);

interface BaseModalController extends angular.IStrictController {}

module.component('baseModal', {
    template: template,
    transclude: true,
    bindings: {
        mainTitle: '@',
        icon: '@',
        subTitle: '@',
        testId: '@',
        description: '<?',
        primaryButtonAction: '&?',
        primaryButtonText: '@?',
        secondaryButtonAction: '&?',
        secondaryButtonText: '@?',
        disablePrimaryButton: '<',
        disableSecondaryButton: '<',
        showPrimaryButtonSpinner: '<?'
    },
    controller: function(this: BaseModalController) {}
});

export { module };

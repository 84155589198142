import * as angular from 'angular';
import * as template from './template.html';
import './styling.scss';
import * as SupportComponents from './components';

const module = angular.module('support-page', [SupportComponents.module.name]);

interface SupportPageController extends angular.IStrictController {
    attemptedLogin?: boolean;
    loginAttemptSucceeded?: boolean;
    loginAttemptFailed?: boolean;
    unknownLoginError?: boolean;

    hasLoginError(): boolean;
}

module.component('supportPage', {
    template: template,
    bindings: {},
    controller: function(this: SupportPageController) {
        this.hasLoginError = () => {
            return !!(this.loginAttemptFailed || this.unknownLoginError);
        };

        this.$onInit = () => {};
    }
});

export { module };

import * as angular from 'angular';
import * as hippoConstFactory from 'common/hippoConst.js';
import './styling.scss';
import { getFormattedPhoneNumber } from 'common/source/utilities/strings';

const module = angular.module('hippo-number', []);

interface HippoNumberController extends angular.IStrictController {
    telephoneNumber: string;
    type: string;
    class: string;
    format: string;
    formattedNumber: string;
}

module.component('hippoNumber', {
    bindings: {
        type: '@',
        class: '@',
        format: '@'
    },
    template: `<a href="tel:{{$ctrl.telephoneNumber}}" class="{{$ctrl.class}}">{{$ctrl.formattedNumber}}</a>`,
    controller: function(this: HippoNumberController) {
        this.$onInit = () => {
            const HIPPO_CONSTS = hippoConstFactory();

            if (this.type === 'sales') {
                this.telephoneNumber = HIPPO_CONSTS.HIPPO_SALES_PHONE_NUMBER;
            } else if (this.type === 'support') {
                this.telephoneNumber = HIPPO_CONSTS.HIPPO_SUPPORT_PHONE_NUMBER;
            } else if (this.type === 'claims') {
                this.telephoneNumber = HIPPO_CONSTS.HIPPO_CLAIMS_PHONE_NUMBER;
            }

            this.formattedNumber = getFormattedPhoneNumber(this.telephoneNumber, this.format);
        };
    }
});

export { module };

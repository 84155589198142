import * as angular from 'angular';
import { NavigationService } from 'services/navigation';
import { HippoApiService } from 'services/hippoApi';

export const module = angular.module('authenticationService', ['ngCookies']);

const COOKIE_OPTIONS = {
    path: '/'
};

interface AuthenticationParameters {
    policyNumber: string;
    zipcode: string;
    lastName: string;
}

export interface LoginByAddressParameters {
    password: string;
    last_name: string;
    street: string;
    city: string;
    state: string;
    zip: string;
}

export class AuthenticationService {
    private $window: angular.IWindowService;
    private $cookies: angular.cookies.ICookiesService;
    private navigationService: NavigationService;
    private hippoApiService: HippoApiService;

    private policyNumberKey: string;
    private zipcodeKey: string;
    private lastNameKey: string;
    private loginTypeKey: string;

    constructor(
        $cookies: angular.cookies.ICookiesService,
        $window: angular.IWindowService,
        navigationService: NavigationService,
        hippoApiService: HippoApiService
    ) {
        this.$cookies = $cookies;
        this.$window = $window;
        this.navigationService = navigationService;
        this.hippoApiService = hippoApiService;

        this.policyNumberKey = 'policyNumber';
        this.zipcodeKey = 'zipcode';
        this.lastNameKey = 'lastName';
        this.loginTypeKey = 'loginType';
    }

    private setSessionCookie(key: string, data: string) {
        this.$cookies.put(key, data, COOKIE_OPTIONS);
    }

    private getSessionCookie(key: string) {
        return this.$cookies.get(key);
    }

    private unsetSessionCookie(key: string | Array<string>) {
        if (Array.isArray(key)) {
            key.forEach((k: string) => this.$cookies.remove(k, COOKIE_OPTIONS));
        } else {
            this.$cookies.remove(key, COOKIE_OPTIONS);
        }
    }

    private setLoginCookie(policyNumber: string, zipcode: string, lastName: string, loginType: string) {
        this.setSessionCookie(this.policyNumberKey, policyNumber);
        this.setSessionCookie(this.zipcodeKey, zipcode);
        this.setSessionCookie(this.lastNameKey, lastName);
        this.setSessionCookie(this.loginTypeKey, loginType);
    }

    login(policyNumber: string, zipcode: string, lastName: string) {
        return this.hippoApiService.authenticateLender(policyNumber, zipcode, lastName).then(() => {
            this.setLoginCookie(policyNumber, zipcode, lastName, 'login');
        });
    }

    loginByAddress(loginByAddressParameters: LoginByAddressParameters) {
        return this.hippoApiService
            .authenticateByAddress(loginByAddressParameters)
            .then((lenderPolicyInfo: LenderPolicyInfo) => {
                const policyNumber = lenderPolicyInfo.policy_number;
                const lastName = lenderPolicyInfo.personal_information.last_name;
                const zipcode = lenderPolicyInfo.property_data.address.zip;

                // we use the data we received from the back end to provide and store the login details
                // that we previously expected to get from the lender user
                this.setLoginCookie(policyNumber, zipcode, lastName, 'loginByAddress');
            });
    }

    supportLogin(policyNumber: string, password: string) {
        return this.hippoApiService
            .authenticateSupport(policyNumber, password)
            .then((lenderPolicyInfo: LenderPolicyInfo) => {
                const lastName = lenderPolicyInfo.personal_information.last_name;
                const zipcode = lenderPolicyInfo.property_data.address.zip;

                // we use the data we received from the back end to provide and store the login details
                // that we previously expected to get from the lender user
                this.setLoginCookie(policyNumber, zipcode, lastName, 'loginSupport');
            });
    }

    logout(withRedirect: boolean = true) {
        const loginType: LoginType = this.getSessionCookie(this.loginTypeKey) as LoginType;
        this.unsetSessionCookie([this.policyNumberKey, this.zipcodeKey, this.lastNameKey, this.loginTypeKey]);
        if (withRedirect) {
            this.navigationService.toLoginPage(loginType);
        }
    }

    isSupportLogin() {
        const loginType: LoginType = this.getSessionCookie(this.loginTypeKey) as LoginType;
        return loginType === 'loginSupport' || loginType === 'loginByAddress';
    }

    getAuthenticationParameters(): AuthenticationParameters {
        const policyNumber = this.getSessionCookie(this.policyNumberKey);
        const zipcode = this.getSessionCookie(this.zipcodeKey);
        const lastName = this.getSessionCookie(this.lastNameKey);

        return { policyNumber, zipcode, lastName };
    }

    isLoggedIn() {
        const { policyNumber, zipcode, lastName } = this.getAuthenticationParameters();
        return !!(policyNumber && zipcode && lastName);
    }
}

module.factory('authenticationService', function(
    $cookies: angular.cookies.ICookiesService,
    $window: angular.IWindowService,
    navigationService: NavigationService,
    hippoApiService: HippoApiService
) {
    'ngInject';

    return new AuthenticationService($cookies, $window, navigationService, hippoApiService);
});

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { IOnChangesObject } from 'angular';

// TODO: write tests for escrow-form

const module = angular.module('escrow-form', []);

interface EscrowFormController extends angular.IStrictController {
    escrowDetails: EscrowDetails;
    cancel: () => void;
    save: () => void;

    // these are passed in
    onSave: () => void;
    onCancel: () => void;
    readOnly: boolean;
    disableSaveOnPristineForm: boolean;
    disableSaveButton: () => boolean;
    useAdditionalLenderStrings: boolean;
    onPaymentMethodChange: () => void;
}

interface EscrowPaymentMethodScope extends angular.IScope {
    escrowForm: angular.IFormController;
}

module.component('escrowForm', {
    bindings: {
        escrowDetails: '<',
        readOnly: '<',
        onSave: '&',
        onCancel: '&',
        onInputChange: '&',
        disableSaveOnPristineForm: '<',
        useAdditionalLenderStrings: '<',
        onPaymentMethodChange: '&'
    },
    template: template,
    controller: function(
        this: EscrowFormController,
        $element: angular.IAugmentedJQuery,
        $timeout: angular.ITimeoutService
    ) {
        this.$onInit = () => {
            $timeout(() => {
                const lenderNameInput = document.querySelector('#lender-name');

                if (lenderNameInput) {
                    (lenderNameInput as HTMLElement).focus();
                }
            });
        };

        this.cancel = () => {
            this.onCancel();
        };

        this.save = () => {
            const form = $element.find('form').scope() as EscrowPaymentMethodScope;
            const escrowForm = form.escrowForm;

            escrowForm.$setSubmitted();

            $timeout(() => {
                // If there is an error, focus on the first input with an error.
                // Otherwise, exit the edit mode.
                const inputWithError = document.querySelector('.escrow-form .has-error input');

                if (inputWithError) {
                    (inputWithError as HTMLElement).focus();
                } else {
                    this.onSave();
                    this.onPaymentMethodChange();
                }
            });
        };

        this.$onChanges = (onChangesObj: IOnChangesObject) => {
            const escrowDetailsChange = onChangesObj.escrowDetails;
            const formScope = $element.find('form').scope() as EscrowPaymentMethodScope;
            if (
                escrowDetailsChange &&
                !escrowDetailsChange.isFirstChange() &&
                escrowDetailsChange.currentValue !== undefined &&
                formScope.escrowForm
            ) {
                formScope.escrowForm.$setDirty();
            }
        };
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

const module = angular.module('unauthenticated-layout', []);

module.component('unauthenticatedLayout', {
    template: template,
    bindings: {},
    transclude: true
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { UsSpinnerService } from '../../types/angular-spinner';

const module = angular.module('hippo-button', []);

interface HippoButtonController extends angular.IStrictController {
    size: string;
    inverted: string;
    color: string;
    disableOnClick: boolean;
    disabledAttr: string; // passed in
    isDisabled: boolean;
    showSpinner: boolean;
}

/*

Usage:

<hippo-button size="large" color="green" inverted="true">test</hippo-button>

You should add a normal ng-click to your button to add a click response.

<hippo-button ng-click="action()" size="large" color="green">test</hippo-button>

If you need a specific width for your button, set the width via CSS.

.context {
    hippo-button {
        width: 150px;
    }
}

*/

module.component('hippoButton', {
    template: template,
    transclude: true,
    bindings: {
        size: '@',
        inverted: '@?',
        color: '@',
        disableOnClick: '@?',
        disabledAttr: '@?disabled',
        showSpinner: '<?'
    },
    controller: function(
        this: HippoButtonController,
        $element: angular.IAugmentedJQuery,
        $attrs: angular.IAttributes,
        $timeout
    ) {
        if ($attrs.uiSref) {
            // if the button has a ui-sref on it, prevent the event bubbling to any container element that also might
            // have a ui-sref, which would cause the click to go to the container's sref

            $element.on('click', (event) => {
                event.stopPropagation();
            });
        }

        this.$onChanges = (changesObj) => {
            const disabledAttr = changesObj.disabledAttr;
            const showSpinner = changesObj.showSpinner;

            if (disabledAttr && disabledAttr.currentValue !== undefined) {
                const isDisabled = disabledAttr.currentValue === 'disabled';
                $timeout(() => {
                    $attrs.$set('disabled', isDisabled ? 'disabled' : null);
                    this.isDisabled = isDisabled;
                });
            }

            if (showSpinner && showSpinner.currentValue !== undefined) {
                $timeout(() => {
                    toggleSpinner(showSpinner.currentValue);
                });
            }
        };

        const toggleSpinner = (show: boolean) => {
            if (show) {
                $element.addClass('show-spinner');
                this.isDisabled = true;
                this.showSpinner = true;
            } else {
                $element.removeClass('show-spinner');
                this.isDisabled = false;
                this.showSpinner = false;
            }
        };

        this.$onInit = () => {
            if (this.inverted === 'true') {
                $element.addClass('inverted');
            }

            $element.addClass(this.color);
            $element.addClass(this.size);

            this.isDisabled = this.disabledAttr === 'disabled';

            if (this.isDisabled) {
                $element.attr('disabled', 'disabled');
            }

            if (this.showSpinner) {
                toggleSpinner(true);
            }

            $element.on('click', (event) => {
                if (this.isDisabled) {
                    event.stopImmediatePropagation();
                } else if (this.disableOnClick) {
                    this.isDisabled = true;
                    $element.attr('disabled', 'disabled');
                }
            });
        };
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';

import { getCityStateAndZipcode, getStreetAddress } from 'common/source/utilities/policyInfoDisplay';
import { ModalService } from 'common/source/services/modal';
import { isPolicyCancelled } from 'common/source/utilities/policyInfoHelper';

const module = angular.module('property-address', []);

interface PropertyAddressController extends angular.IStrictController {
    title: string;
    icon: string;
    policyInfo: LenderPolicyInfo;
    cityStateAndZipCode: string;
    streetAddress: string;
    isCancelled: boolean;
    edit: () => void;
    updateDisplay: () => void;
}

module.component('propertyAddress', {
    template: template,
    bindings: {
        policyInfo: '<'
    },
    controller: function(this: PropertyAddressController, modalService: ModalService) {
        'ngInject';

        this.$onChanges = () => {
            this.cityStateAndZipCode = getCityStateAndZipcode(this.policyInfo);
            this.streetAddress = getStreetAddress(this.policyInfo);
        };

        this.$onInit = () => {
            this.icon = 'house';
            this.title = 'Property Address';
            this.isCancelled = isPolicyCancelled(this.policyInfo);
        };

        this.edit = () => {
            modalService.openUncloseableModal('edit-property-address');
        };
    }
});

export { module };

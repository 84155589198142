import * as angular from 'angular';

import * as AppHeaderLayoutComponent from 'common/source/components/app-header';
import * as MobileAppHeaderComponent from './mobile';
import * as DesktopAppHeaderComponent from './desktop';

const module = angular.module('AppHeaderComponents', [
    AppHeaderLayoutComponent.module.name,
    MobileAppHeaderComponent.module.name,
    DesktopAppHeaderComponent.module.name
]);

export { module };

import * as angular from 'angular';
import * as _ from 'lodash';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';

export const module = angular.module('mortgageService', []);

export class MortgageService {
    private lenderPolicyInfoService: LenderPolicyInfoService;

    constructor(lenderPolicyInfoService: LenderPolicyInfoService) {
        this.lenderPolicyInfoService = lenderPolicyInfoService;
    }

    getMortgages() {
        const policyInfo = this.lenderPolicyInfoService.getPolicyInfo();
        const mortgages = _.get(policyInfo, 'property_data.mortgages.details', []);
        return mortgages.sort((a: MortgageDetails, b: MortgageDetails) => {
            if (a.type === 'first') {
                return -1;
            } else if (_.includes(['second', 'third'], a.type) && _.includes(['first', 'second'], b.type)) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    getOrdinal(number: number) {
        let ordinal = 'st';
        if (number === 1) {
            ordinal = 'st';
        } else if (number === 2) {
            ordinal = 'nd';
        } else if (number === 3) {
            ordinal = 'rd';
        }
        return ordinal;
    }

    getOrdinalText(number: number): MortgageDetailsType | null {
        if (number === 1) {
            return 'first';
        } else if (number === 2) {
            return 'second';
        } else if (number === 3) {
            return 'third';
        }
        return null;
    }

    covertMortgageDetailsToEscrowDetails(mortgageDetails: MortgageDetails): EscrowDetails {
        const escrowDetails: EscrowDetails = {
            lender_mailing_address: {
                street: mortgageDetails.street,
                city: mortgageDetails.city,
                state: mortgageDetails.state,
                zip: mortgageDetails.zip
            },
            loan_number: mortgageDetails.number,
            lender_name: mortgageDetails.name
        };
        if (mortgageDetails.street2) {
            escrowDetails.lender_mailing_address.street2 = mortgageDetails.street2;
        }
        return escrowDetails;
    }

    convertEscrowDetailsToMortgageDetails(escrowDetails: EscrowDetails): PartialMortgageDetails {
        const mortgageDetails: PartialMortgageDetails = {
            street: escrowDetails.lender_mailing_address.street,
            city: escrowDetails.lender_mailing_address.city,
            state: escrowDetails.lender_mailing_address.state,
            zip: escrowDetails.lender_mailing_address.zip,
            number: escrowDetails.loan_number,
            name: escrowDetails.lender_name
        };
        if (escrowDetails.lender_mailing_address.street2) {
            mortgageDetails.street2 = escrowDetails.lender_mailing_address.street2;
        }
        return mortgageDetails;
    }

    getFirstLien(policyInfo: LenderPolicyInfo): MortgageDetails | undefined {
        const mortgageDetailsArray = policyInfo.property_data.mortgages.details;
        let firstLien;
        mortgageDetailsArray.forEach((mortgageDetails: MortgageDetails) => {
            if (this.isFirstLien(mortgageDetails)) {
                firstLien = mortgageDetails;
            }
        });
        return firstLien;
    }

    isFirstLien(mortgageDetails: MortgageDetails): boolean {
        return mortgageDetails.type === 'first';
    }

    createNewMortgage() {
        const mortgages = this.getMortgages();
        const getNextMortgageeType = () => {
            const numMortgages = mortgages.length;
            const typeMapping = ['first', 'second', 'third'];
            return typeMapping[numMortgages] as MortgageDetailsType;
        };

        return {
            billed: false,
            city: '',
            id: `new${Date.now()}`,
            loan_officer_contact: '',
            loan_officer_fullname: '',
            name: '',
            number: '',
            state: '',
            street: '',
            street2: '',
            type: getNextMortgageeType(),
            zip: ''
        };
    }
}

module.factory('mortgageService', function(lenderPolicyInfoService: LenderPolicyInfoService) {
    'ngInject';

    return new MortgageService(lenderPolicyInfoService);
});

import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { NavigationService } from 'services/navigation';

const module = angular.module('logout', []);

module.component('logout', {
    template: template,
    controller: function(this: angular.IComponentController, navigationService: NavigationService) {
        this.logout = () => {
            navigationService.toLogoutPage();
        };
    }
});

export { module };

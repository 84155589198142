import * as angular from 'angular';
import './styling.scss';
import * as template from './template.html';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import * as _ from 'lodash';
import { MortgageService } from 'services/mortgage';

const module = angular.module('edit-escrow-details', []);

interface EditEscrowDetailsController extends angular.IStrictController {
    escrowDetails: EscrowDetails;
    firstMortgageDetails: MortgageDetails;
    hasFirstLien: boolean;
    matchesFirstLien: () => boolean;
    save: () => void;
    cancel: () => void;
    onInputChange: () => void;
    mainTitle: string;
    subTitle: string;
    icon: string;
    policyInfo: LenderPolicyInfo;
    firstLienAsEscrowDetails: EscrowDetails;
}

module.component('editEscrowDetails', {
    template: template,
    controller: function(
        this: EditEscrowDetailsController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        mortgageService: MortgageService,
        modalService: ModalService
    ) {
        let policyInfo: LenderPolicyInfo | undefined;

        const setUpFirstLien = (mortgageDetails: MortgageDetails) => {
            this.hasFirstLien = true;
            this.firstMortgageDetails = mortgageDetails;
            this.firstLienAsEscrowDetails = mortgageService.covertMortgageDetailsToEscrowDetails(
                this.firstMortgageDetails
            );
        };

        this.$onInit = () => {
            policyInfo = lenderPolicyInfoService.getPolicyInfo();
            this.hasFirstLien = false;

            if (policyInfo) {
                if (policyInfo.checkout_data) {
                    this.escrowDetails = {
                        lender_mailing_address: {
                            ...policyInfo.checkout_data.lender_mailing_address
                        } as LenderMailingAddress,
                        lender_name: policyInfo.checkout_data.lender_name as string,
                        loan_number: policyInfo.checkout_data.loan_number as string
                    };
                }

                const firstLien = mortgageService.getFirstLien(policyInfo);
                if (firstLien) {
                    setUpFirstLien(firstLien);
                }
            }
            this.mainTitle = 'Payment Method';
            this.subTitle = 'Want to change the payment information?';
            this.icon = 'green-money';
        };

        const getCompleteCheckoutData = () => {
            if (policyInfo) {
                return {
                    ...policyInfo.checkout_data,
                    lender_mailing_address: {
                        ...this.escrowDetails.lender_mailing_address
                    },
                    lender_name: this.escrowDetails.lender_name,
                    loan_number: this.escrowDetails.loan_number
                };
            }
        };

        this.save = () => {
            const completeCheckoutData = getCompleteCheckoutData();
            if (policyInfo && completeCheckoutData) {
                policyInfo.checkout_data = completeCheckoutData;

                // Apply changes to First Lien as well
                const firstLien = mortgageService.getFirstLien(policyInfo);
                _.extend(firstLien, mortgageService.convertEscrowDetailsToMortgageDetails(this.escrowDetails));

                lenderPolicyInfoService.setPolicyInfo(policyInfo);
                modalService.closeAllDialogs();
            }
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };

import * as angular from 'angular';
import './styling.scss';
import * as _ from 'lodash';
import * as template from './template.html';
import { LenderPolicyInfoService } from 'services/lenderPolicyInfo';
import { ModalService } from 'common/source/services/modal';
import { MortgageService } from 'services/mortgage';
import { HippoApiService } from 'services/hippoApi';
import { AuthenticationService } from 'services/authentication';

const module = angular.module('confirm-eoi-updates', []);

interface ConfirmEOIUpdatesController extends angular.IStrictController {
    submit: () => void;
    cancel: () => void;
    data: {
        ngDialogId: string;
        showContactForm?: boolean;
    };
    showSpinner: boolean;
    showContactForm: boolean;
    description: string;
    mainTitle: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    contactName: string;
    contactEmail: string;
}

interface ConfirmChangesContactFormScope extends angular.IScope {
    reviewChangeContact: angular.IFormController;
}

module.component('confirmEoiUpdates', {
    template: template,
    bindings: {
        data: '<?'
    },
    controller: function(
        this: ConfirmEOIUpdatesController,
        lenderPolicyInfoService: LenderPolicyInfoService,
        hippoApiService: HippoApiService,
        authenticationService: AuthenticationService,
        mortgageService: MortgageService,
        modalService: ModalService,
        $element: angular.IAugmentedJQuery
    ) {
        this.$onInit = () => {
            this.showSpinner = false;
            this.showContactForm = !!this.data.showContactForm;
            updateTextVersion();
        };

        const updateTextVersion = () => {
            if (this.showContactForm) {
                this.mainTitle = '';
                this.primaryButtonText = 'Send';
                this.description = `
                    Changes received! Looks like additional review is needed.
                    We will send you the updated version of the EOI after changes are approved.
                    Let us know how we can contact you:
                `;
                this.secondaryButtonText = '';
            } else {
                this.primaryButtonText = 'Submit';
                this.secondaryButtonText = 'Cancel';
                this.mainTitle = 'Ready to submit your changes?';
                this.description = `
                    Final step! Once you submit the changes, you won’t be able to make further edits until all
                    changes are approved. We will review your updates and notify you when there is a new EOI
                    generated for you to download.`;
            }
        };

        const sendPolicyUpdate = () => {
            const lenderPolicyInfo = lenderPolicyInfoService.getPolicyInfo();
            const { policyNumber, zipcode, lastName } = authenticationService.getAuthenticationParameters();
            const lenderPolicyInfoUpdate = {
                zip: zipcode,
                last_name: lastName,
                policy_number: policyNumber,
                ...lenderPolicyInfo
            };

            this.showSpinner = true;
            hippoApiService
                .updatePolicyInfo(lenderPolicyInfoUpdate)
                .then((result) => {
                    if (result.data) {
                        this.showContactForm = _.isPlainObject(result.data.transaction);
                        if (!this.showContactForm) {
                            modalService.closeAllDialogs();
                        }
                        lenderPolicyInfoService.setPolicyInfo(result.data, true);
                        updateTextVersion();
                    }
                })
                .catch(() => {
                    modalService.closeAllDialogs();
                })
                .then(() => {
                    this.showSpinner = false;
                });
        };

        const sendContactInformation = () => {
            const form = $element.find('form').scope() as ConfirmChangesContactFormScope;
            const contactForm = form.reviewChangeContact;
            contactForm.$setSubmitted();
            if (contactForm.$valid) {
                const name = this.contactName;
                const email = this.contactEmail;
                const loginInfo = authenticationService.getAuthenticationParameters();
                hippoApiService.updateLastEditor(loginInfo, name, email).then((result) => {
                    lenderPolicyInfoService.setPolicyInfo(result.data, true);
                    modalService.closeAllDialogs();
                });
            } else {
                const firstInvalidInput = document.querySelector('input.ng-invalid') as HTMLInputElement;
                if (firstInvalidInput) {
                    firstInvalidInput.focus();
                }
            }
        };

        this.submit = () => {
            return this.showContactForm ? sendContactInformation() : sendPolicyUpdate();
        };

        this.cancel = () => {
            modalService.closeAllDialogs();
        };
    }
});

export { module };
